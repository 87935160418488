import {action, makeAutoObservable} from 'mobx'
import {gameService} from './GameService'
import HttpStatusCode from '../../common/constants/HttpErrorCode'
import {toastUtil} from '../../common/utils/ToastUtil'
import $ from 'jquery'
import {bannerService} from '../banner/BannerService'

class GameStore {
  public isLoading: boolean = false
  public isLoadingBt: boolean = false
  public gameList: any = []
  public id: any = ""
  public page: number = 0
  public totalPages: number = 0
  public dataRequest: any = {
    name: '',
    secretKey: '',
    imageUrl: '',
    thumbUrl: '',
    thumbChallengeUrl: '',
    banner: '',
    categoryId: '',
    gameTypeId: [],
    gameType: '',
    priority: '',
    navLink: '',
    apiVerifyAccount: '',
    bodyApiVerifyAccount: '',
    apiPayment: '',
    bodyApiPayment: '',
    isHot: false,
    isNew: false,
    isUpdate: false,
    isLock: false,
    levelUnlock: 0,
    teamId: '',
    linkAds: ''
  }
  public gameCategory: any
  public gameType: { value: string, label: string }[] = []
  public gameTypeDefault: any
  public gameLevel: any = []
  public publisher: any = []
  public searchId: string = ""
  public searchName: string = ""
  public searchCategory: string = ""
  public searchState: string = ""
  public searchHot: boolean = false
  public searchNew: boolean = false

  constructor() {
    makeAutoObservable(this, {
      clearForm: action,
      getList: action,
      getDetail: action,
      create: action,
      update: action,
      delete: action,
      getCategoryAndType: action,
    })
  }

  clearForm = () => {
    this.dataRequest = {
      name: '',
      secretKey: '',
      imageUrl: '',
      thumbUrl: '',
      thumbChallengeUrl: '',
      banner: '',
      categoryId: '',
      gameType: '',
      priority: '',
      navLink: '',
      apiVerifyAccount: '',
      bodyApiVerifyAccount: '',
      apiPayment: '',
      bodyApiPayment: '',
      isHot: false,
      isNew: false,
      isUpdate: false,
      isLock: false,
      levelUnlock: 0,
      teamId: '',
      linkAds: ''
    }
  }

  getList = async () => {
    this.isLoading = true
    const result = await gameService.getList()
    this.isLoading = false
    if (result.status === HttpStatusCode.OK) {
      this.gameList = result.body.data
      this.totalPages = result.body.metadata.totalPages
    } else {
      this.gameList = []
    }
  }

  getDetail = async (id: any) => {
    this.gameTypeDefault = []
    this.isLoading = true
    const result = await gameService.getDetail(id)
    this.isLoading = false
    if (result.status === HttpStatusCode.OK) {
      this.dataRequest = result.body
      this.gameTypeDefault = this.dataRequest.gameType.map(item => ({value: item.id, label: item.name}))
      console.log(this.gameTypeDefault)
      console.log(JSON.stringify(this.gameTypeDefault))
      console.log(JSON.parse(JSON.stringify(this.gameTypeDefault)))
    } else {
      toastUtil.error(result.body.message)
    }
  }

  create = async () => {
    let data: any = this.dataRequest
    data.gameTypeId = this.dataRequest.gameType ? this.dataRequest.gameType.map(item => (item.value)) : []

    this.isLoadingBt = true
    const result = await gameService.add(data)
    this.isLoadingBt = false
    if (result.status === HttpStatusCode.OK) {
      toastUtil.success('Create success')
      setTimeout(() => {
        window.location.href = "/game/list";
      }, 500)
      this.clearForm()
      await this.getList()
    } else toastUtil.error(result.body.message)

  }

  update = async () => {
    let data: any = this.dataRequest
    data.gameTypeId = this.dataRequest.gameType ? this.dataRequest.gameType.map(item => (item.value)) : []

    this.isLoadingBt = true
    const result = await gameService.update(this.dataRequest.id, data)
    this.isLoadingBt = false
    if (result.status === HttpStatusCode.OK) {
      toastUtil.success('Update success')
      setTimeout(() => {
        this.getDetail(this.id)
        window.scroll(0, 0)
      }, 500)
      this.clearForm()
    } else toastUtil.error(result.body.message)
  }

  delete = async () => {
    this.isLoadingBt = true
    const result = await gameService.delete(this.id)
    this.isLoadingBt = false
    if (result.status === HttpStatusCode.OK) {
      $('#delete_close').trigger('click')
      toastUtil.success('Delete success')
      await this.getList()
    } else toastUtil.error(result.body.message)
  }

  uploadIcon = async (type: string) => {
    var formData = new FormData()
    const file = this.dataRequest.file
    formData.append('image_url', file)
    const result = await bannerService.upload(formData)
    if (result.status === HttpStatusCode.OK) {
      if (type === 'imageUrl') {
        this.dataRequest.imageUrl = result.body.imgUrl
      }
      if (type === 'thumbUrl') {
        this.dataRequest.thumbUrl = result.body.imgUrl
      }
      if (type === 'thumbChallengeUrl') {
        this.dataRequest.thumbChallengeUrl = result.body.imgUrl
      }
      console.log(result.body)
      console.log(result.body.imgUrl)
      console.log(this.dataRequest)
    } else {
      toastUtil.error(result.body.message ? result.body.message : 'Error.')
    }
  }

  getCategoryAndType = async () => {
    this.gameCategory = []
    this.gameType = []
    const result = await gameService.getCategory()
    const res = await gameService.getType()
    if (result.status === HttpStatusCode.OK) {
      this.gameCategory = result.body
    }
    if (res.status === HttpStatusCode.OK) {
      this.gameType = res.body.map(item => ({value: item.id, label: item.name}))
    }
  }

  getLevel = async () => {
    const result = await gameService.getLevel()
    if (result.status === HttpStatusCode.OK) {
      this.gameLevel = result.body
    }
  }

  getPublisher = async () => {
    const result = await gameService.getPublisher()
    if (result.status === HttpStatusCode.OK) {
      this.publisher = result.body
    }
  }

}

export const gameStore = new GameStore()
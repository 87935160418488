import {action, makeAutoObservable} from 'mobx'
import {userService} from './UserService'
import HttpStatusCode from '../../common/constants/HttpErrorCode'
import {toastUtil} from '../../common/utils/ToastUtil'
import $ from 'jquery'
import {gameService} from "../gameList/GameService";
import {dateUtils} from "../../common/utils/DateUtils";

export enum TabUser {
  characterHistory = 'characterHistory',
  characterUser = 'characterUser',
  subHistory = 'subHistory',
  coinTransaction = 'coinTransaction',
  pointTransaction = 'pointTransaction',
  expTransaction = 'expTransaction',
  spinTransaction = 'spinTransaction',
  ticketTransaction = 'ticketTransaction',
  rewardHistory = 'rewardHistory',
  achievementUser = 'achievementUser',
  collectionHistory = 'collectionHistory',
  collectionUser = 'collectionUser',
  luckyPointUser = 'LuckyPointUser',
  questTransaction = 'questTransaction',
  goldPig = 'goldPig',
  taskDaily = 'taskDaily',
  package = 'package',
  transaction = 'transaction',
}

export enum TabCampaign {
  collection = 'collection',
  reward = 'reward',
  task = 'task'
}


export enum Type {
  FIRST = 'FIRST',
  NEXT = 'NEXT',
  PRE = 'PRE'
}

class UserStore {
  public userId: any = ''
  public next: any = ''
  public pre: any = ''
  public metadata: any = []
  public typePagination: any = Type.FIRST
  public countPage: number = 0
  public loading: boolean = false
  public isLoading: boolean = false
  public isLoadingBt: boolean = false
  public isLoadingGet: boolean = false
  public isAction: boolean = false
  public dataChange: any =
    {
      type: 'ADD',
      requestType: 'ADD',
      amount: 0,
      note: '',
      resourceType: null,
      monthId: null,
      userId: '',
      itemId: '',
      score: 0
    }
  public dataSub: any =
    {
      userId: '',
      type: "SAND_BOX",
      packageCode: "",
      transactionId: '',
      startAt: new Date()
    }
  public dataPackage: any =
    {
      userId: '',
      serviceId: '',
      paymentMethod: 'SMS',
      partner: 'MYTEL',
      type: '',
      note: ''
    }
  public tabActive: any = TabUser.package
  public searchName: string = ''
  public searchPhone: string = ''
  public page: number = 0
  public date: any = ''
  public gte: any = new Date()
  public lte: any = new Date()
  public startDate: any = ""
  public endDate: any = ""
  public searchDate: any = null
  public phoneNumber: any = ''
  public displayName: any = ''
  public searchExternalId: any = ''
  public name: any = ''
  public searchId: any = ''
  public searchTranId: any = ''
  public searchProductId: any = ''
  public searchProductName: any = ''
  public searchLevel: any = ''
  public searchSub: any = ''
  public totalPages: number = 0
  public userList: any[] = []
  public userInfo: any = []
  public listLevel: any = []

  public userPackage: any[] = []
  public userTransaction: any[] = []
  public characterHistory: any[] = []
  public characterUser: any[] = []
  public subHistory: any[] = []
  public coinTransaction: any[] = []
  public pointTransaction: any[] = []
  public expTransaction: any[] = []
  public listSpinTransaction: any[] = []
  public ticketTransaction: any[] = []
  public rewardHistory: any[] = []
  public achievementUser: any[] = []
  public collectionHistory: any[] = []
  public collectionUser: any[] = []
  public luckyPointUser: any[] = []
  public questTransaction: any[] = []
  public goldPig: any[] = []
  public taskDaily: any[] = []
  public productList: any = []
  public achievementList: any = []
  public collectionList: any = []
  public taskList: any = []
  public characterList: any = []
  public idCancel: any = ''
  public searchType: string = 'Username'
  public searchState: string = ''

  public dataCollection: any = {
    userId: '',
    requestType: 'ADD',
    collectionId: '',
    amount: 0,
    note: '',
  }

  constructor() {
    makeAutoObservable(this, {
      getUsers: action,
      getUserDetail: action,
      getSubHistory: action,
      getSpinTransaction: action,
      getCharacterHistory: action,
      getCharacterUser: action,
      getCharacter: action,
      getLevel: action,
      getQuestTransaction: action,
      getProduct: action,
      changeSpin: action,
      changePoint: action,
      changeCoin: action,
      changeSub: action,
      changeTicket: action,
      changeExp: action,
      changeAchievement: action,
      changeCollection: action,
      changeLuckyPoint: action,
      changePointTotal: action,
      cancelSub: action,
      getPointTransaction: action,
      getCollectionUser: action,
      getAchievementUser: action,
      getRewardHistory: action,
      getTicketTransaction: action,
      getLuckyPointUser: action,
      getCoinTransaction: action,
      getExpTransaction: action,
      getGoldPig: action,
      getTaskDaily: action
    })
  }

  clear() {
    this.page = 0
    this.totalPages = 0
    this.searchId = ''
    this.date = ''
    this.searchDate = null
    this.typePagination = Type.FIRST
  }

  clearForm() {
    this.dataChange = {
      type: 'ADD',
      requestType: 'ADD',
      amount: 0,
      note: '',
      resourceType: null,
      monthId: null,
      userId: '',
      itemId: '',
      score: 0
    }

    this.dataCollection = {
      userId: '',
      requestType: 'ADD',
      itemId: '',
      amount: 0,
      note: '',
    }

    this.searchId = ''
    this.searchName = ''
    this.searchExternalId = ''
  }

  getUsers = async () => {
    this.isLoading = true
    const result = await userService.getUser()
    this.isLoading = false
    if (result.status === HttpStatusCode.OK) {
      this.userList = result.body.data
      this.metadata = result.body.metadata
    } else {
      toastUtil.error(result.body.message)
    }
  }

  getProduct = async () => {
    const result = await userService.getProduct()
    if (result.status === HttpStatusCode.OK) {
      this.productList = result.body
    } else {
      toastUtil.error(result.body.message)
    }
  }

  getAchievement = async () => {
    const result = await userService.getAchievement()
    if (result.status === HttpStatusCode.OK) {
      this.achievementList = result.body
    } else {
      toastUtil.error(result.body.message)
    }
  }

  getCollection = async () => {
    const result = await userService.getCollection()
    if (result.status === HttpStatusCode.OK) {
      this.collectionList = result.body
    } else {
      toastUtil.error(result.body.message)
    }
  }

  getTask = async () => {
    const result = await userService.getTask()
    if (result.status === HttpStatusCode.OK) {
      this.taskList = result.body
    } else {
      toastUtil.error(result.body.message)
    }
  }

  getCharacter = async () => {
    const result = await userService.getCharacter()
    if (result.status === HttpStatusCode.OK) {
      this.characterList = result.body
    } else {
      toastUtil.error(result.body.message)
    }
  }

  getLevel = async () => {
    const result = await gameService.getLevel()
    if (result.status === HttpStatusCode.OK) {
      this.listLevel = result.body
    }
  }

  getUserDetail = async (id: any) => {
    this.isLoading = true
    const result = await userService.userDetail(id)
    this.isLoading = false
    if (result.status === HttpStatusCode.OK) {
      userStore.userInfo = result.body
    } else {
      toastUtil.error(result.body.message)
    }
  }

  getUserPackage = async (id: any) => {
    this.isLoadingGet = true
    const result = await userService.userPackage(id)
    this.isLoadingGet = false
    if (result.status === HttpStatusCode.OK) {
      this.userPackage = result.body.data
      this.metadata = result.body.metadata
    } else {
      toastUtil.error(result.body.message)
    }
  }

  getUserTransaction = async (id: any) => {
    this.isLoadingGet = true
    const result = await userService.userTransaction(id)
    this.isLoadingGet = false
    if (result.status === HttpStatusCode.OK) {
      this.userTransaction = result.body.data
      this.metadata = result.body.metadata
    } else {
      toastUtil.error(result.body.message)
    }
  }

  getCharacterHistory = async (id: any) => {
    this.isLoadingGet = true
    const result = await userService.characterHistory(id)
    this.isLoadingGet = false
    if (result.status === HttpStatusCode.OK) {
      this.characterHistory = result.body.data
      this.metadata = result.body.metadata
    } else {
      toastUtil.error(result.body.message)
    }
  }

  getCharacterUser = async (id: any) => {
    this.isLoadingGet = true
    const result = await userService.characterUser(id)
    this.isLoadingGet = false
    if (result.status === HttpStatusCode.OK) {
      this.characterUser = result.body.data
      this.metadata = result.body.metadata
    } else {
      toastUtil.error(result.body.message)
    }
  }

  getSubHistory = async (id: any) => {
    this.isLoadingGet = true
    const result = await userService.getSubHistory(id)
    this.isLoadingGet = false
    if (result.status === HttpStatusCode.OK) {
      this.subHistory = result.body.data
      this.metadata = result.body.metadata
    } else {
      toastUtil.error(result.body.message)
    }
  }

  getCoinTransaction = async (id: any) => {
    this.isLoadingGet = true
    const result = await userService.coinTransaction(id)
    this.isLoadingGet = false
    if (result.status === HttpStatusCode.OK) {
      this.coinTransaction = result.body.data
      this.metadata = result.body.metadata
    } else {
      toastUtil.error(result.body.message)
    }
  }

  getPointTransaction = async (id: any) => {
    this.isLoadingGet = true
    const result = await userService.pointTransaction(id)
    this.isLoadingGet = false
    if (result.status === HttpStatusCode.OK) {
      this.pointTransaction = result.body.data
      this.metadata = result.body.metadata
    } else {
      toastUtil.error(result.body.message)
    }
  }

  getExpTransaction = async (id: any) => {
    this.isLoadingGet = true
    const result = await userService.expTransaction(id)
    this.isLoadingGet = false
    if (result.status === HttpStatusCode.OK) {
      this.expTransaction = result.body.data
      this.metadata = result.body.metadata
    } else {
      toastUtil.error(result.body.message)
    }
  }

  getSpinTransaction = async (id: any) => {
    this.isLoadingGet = true
    const result = await userService.spinTransaction(id)
    this.isLoadingGet = false
    if (result.status === HttpStatusCode.OK) {
      this.listSpinTransaction = result.body.data
      this.metadata = result.body.metadata
    } else {
      toastUtil.error(result.body.message)
    }
  }

  getTicketTransaction = async (id: any) => {
    this.isLoadingGet = true
    const result = await userService.ticketTransaction(id)
    this.isLoadingGet = false
    if (result.status === HttpStatusCode.OK) {
      this.ticketTransaction = result.body.data
      this.metadata = result.body.metadata
    } else {
      toastUtil.error(result.body.message)
    }
  }

  getRewardHistory = async (id: any) => {
    this.isLoadingGet = true
    const result = await userService.rewardHistory(id)
    this.isLoadingGet = false
    if (result.status === HttpStatusCode.OK) {
      this.rewardHistory = result.body.data
      this.metadata = result.body.metadata
    } else {
      toastUtil.error(result.body.message)
    }
  }

  getAchievementUser = async (id: any) => {
    this.isLoadingGet = true
    const result = await userService.achievementUser(id)
    this.isLoadingGet = false
    if (result.status === HttpStatusCode.OK) {
      this.achievementUser = result.body.data
      this.metadata = result.body.metadata
    } else {
      toastUtil.error(result.body.message)
    }
  }

  getCollectionHistory = async (id: any) => {
    this.isLoadingGet = true
    const result = await userService.collectionHistory(id)
    this.isLoadingGet = false
    if (result.status === HttpStatusCode.OK) {
      this.collectionHistory = result.body.data
      this.metadata = result.body.metadata
    } else {
      toastUtil.error(result.body.message)
    }
  }

  getCollectionUser = async (id: any) => {
    this.isLoadingGet = true
    const result = await userService.collectionUser(id)
    this.isLoadingGet = false
    if (result.status === HttpStatusCode.OK) {
      this.collectionUser = result.body.data
      this.metadata = result.body.metadata
    } else {
      toastUtil.error(result.body.message)
    }
  }

  getLuckyPointUser = async (id: any) => {
    this.isLoadingGet = true
    const result = await userService.luckyPointUser(id)
    this.isLoadingGet = false
    if (result.status === HttpStatusCode.OK) {
      this.luckyPointUser = result.body.data
      this.metadata = result.body.metadata
    } else {
      toastUtil.error(result.body.message)
    }
  }

  getQuestTransaction = async (id: any) => {
    this.isLoadingGet = true
    const result = await userService.questTransaction(id)
    this.isLoadingGet = false
    if (result.status === HttpStatusCode.OK) {
      this.questTransaction = result.body.data
      this.metadata = result.body.metadata
    } else {
      toastUtil.error(result.body.message)
    }
  }

  getGoldPig = async (id: any) => {
    this.isLoadingGet = true
    const result = await userService.goldPig(id)
    this.isLoadingGet = false
    if (result.status === HttpStatusCode.OK) {
      this.goldPig = result.body.data
      this.metadata = result.body.metadata
    } else {
      toastUtil.error(result.body.message)
    }
  }

  getTaskDaily = async (id: any) => {
    this.isLoadingGet = true
    const result = await userService.taskDaily(id)
    this.isLoadingGet = false
    if (result.status === HttpStatusCode.OK) {
      this.taskDaily = result.body.data
      this.metadata = result.body.metadata
    } else {
      toastUtil.error(result.body.message)
    }
  }

  exportCashHistory = async () => {
    this.isLoadingBt = true
    const res = await userService.exportCashHistory(this.userId, this.gte, this.lte)
    this.isLoadingBt = false
    if (res.status === HttpStatusCode.OK) {
      toastUtil.success('Downloaded')
    } else {
      toastUtil.error(res.body.message)
    }
  }


  changeSpin = async () => {
    const data: any = {
      userId: this.userId,
      requestType: this.dataChange.type,
      resourceType: this.dataChange.resourceType,
      amount: this.dataChange.amount,
      note: this.dataChange.note,
    }
    if (data.amount < 0) {
      toastUtil.warning('Amount must not be null')
      return
    }
    this.isLoadingBt = true
    const result = await userService.changeSpin(data)
    this.isLoadingBt = false
    if (result.status === HttpStatusCode.OK) {
      $('#changeSpin_close').trigger('click')
      toastUtil.success('Change Success')
      await this.getUserDetail(this.userId)
      this.clearForm()
    } else toastUtil.error(result.body.message)

  }

  changePoint = async () => {
    const data: any = {
      userId: this.userId,
      requestType: this.dataChange.type,
      resourceType: this.dataChange.resourceType,
      amount: this.dataChange.amount,
      note: this.dataChange.note,
    }
    if (data.amount < 0) {
      toastUtil.warning('Amount must not be null')
      return
    }
    this.isLoadingBt = true
    const result = await userService.changePoint(data)
    this.isLoadingBt = false
    if (result.status === HttpStatusCode.OK) {
      $('#changePoint_close').trigger('click')
      toastUtil.success('Change Success')
      await this.getUserDetail(this.userId)
      this.clearForm()
    } else toastUtil.error(result.body.message)
  }

  changePointGoldPig = async () => {
    const data: any = {
      userId: this.userId,
      requestType: this.dataChange.type,
      resourceType: this.dataChange.resourceType,
      amount: this.dataChange.amount,
      note: this.dataChange.note,
    }
    if (data.amount < 0) {
      toastUtil.warning('Amount must not be null')
      return
    }
    this.isLoadingBt = true
    const result = await userService.changePointGoldPig(data)
    this.isLoadingBt = false
    if (result.status === HttpStatusCode.OK) {
      $('#changePointGP_close').trigger('click')
      toastUtil.success('Change Success')
      await this.getUserDetail(this.userId)
      this.clearForm()
    } else toastUtil.error(result.body.message)

  }

  changeCoin = async () => {
    const data: any = {
      userId: this.userId,
      requestType: this.dataChange.type,
      resourceType: this.dataChange.resourceType,
      amount: this.dataChange.amount,
      note: this.dataChange.note,
    }
    if (data.amount < 0) {
      toastUtil.warning('Amount must not be null')
      return
    }
    this.isLoadingBt = true
    const result = await userService.changeCoin(data)
    this.isLoadingBt = false
    if (result.status === HttpStatusCode.OK) {
      $('#changeCoin_close').trigger('click')
      toastUtil.success('Change Success')
      await this.getUserDetail(this.userId)
      this.clearForm()
    } else toastUtil.error(result.body.message)
  }

  changeSub = async () => {
    const data: any = {
      userId: this.userId,
      type: this.dataSub.type,
      packageCode: this.dataSub.packageCode,
      transactionId: this.dataSub.transactionId,
      startAt: dateUtils.getDateString(this.dataSub.startAt)
    }
    if (data.amount < 0) {
      toastUtil.warning('Amount must not be null')
      return
    }
    this.isLoadingBt = true
    const result = await userService.changeSub(data)
    this.isLoadingBt = false
    if (result.status === HttpStatusCode.OK) {
      $('#changeSub_close').trigger('click')
      toastUtil.success('Change Success')
      await this.getUserDetail(this.userId)
      this.clearForm()
    } else toastUtil.error(result.body.message)
  }

  cancelSub = async () => {
    this.isLoadingBt = true
    const result = await userService.cancelSub(this.idCancel)
    this.isLoadingBt = false
    if (result.status === HttpStatusCode.OK) {
      $('#cancelSub_close').trigger('click')
      toastUtil.success('Canceled')
      await this.getUserDetail(this.userId)
      this.clearForm()
    } else toastUtil.error(result.body.message)
  }

  changeCollection = async () => {
    const data: any = {
      userId: this.userId,
      requestType: this.dataChange.type,
      score: this.dataChange.score,
      itemId: this.dataChange.itemId,
      amount: this.dataChange.amount,
    }
    if (data.amount < 0) {
      toastUtil.warning('Amount must not be null')
      return
    }
    this.isLoadingBt = true
    const result = await userService.changeCollection(data)
    this.isLoadingBt = false
    if (result.status === HttpStatusCode.OK) {
      $('#changeCollection_close').trigger('click')
      toastUtil.success('Change Success')
      await this.getUserDetail(this.userId)
      this.clearForm()
    } else toastUtil.error(result.body.message)
  }

  changeCharacter = async () => {
    const data: any = {
      userId: this.userId,
      requestType: this.dataChange.type,
      score: this.dataChange.score,
      itemId: this.dataChange.itemId,
      amount: this.dataChange.amount,
    }
    if (data.amount < 0) {
      toastUtil.warning('Amount must not be null')
      return
    }
    this.isLoadingBt = true
    const result = await userService.changeCharacter(data)
    this.isLoadingBt = false
    if (result.status === HttpStatusCode.OK) {
      $('#changeCharacter_close').trigger('click')
      toastUtil.success('Change Success')
      await this.getUserDetail(this.userId)
      this.clearForm()
    } else toastUtil.error(result.body.message)
  }

  changeTask = async () => {
    const data: any = {
      userId: this.userId,
      requestType: this.dataChange.type,
      score: this.dataChange.score,
      itemId: this.dataChange.itemId,
      amount: this.dataChange.amount,
    }
    if (data.amount < 0) {
      toastUtil.warning('Amount must not be null')
      return
    }
    this.isLoadingBt = true
    const result = await userService.changeTask(data)
    this.isLoadingBt = false
    if (result.status === HttpStatusCode.OK) {
      $('#changeTask_close').trigger('click')
      toastUtil.success('Change Success')
      await this.getUserDetail(this.userId)
      this.clearForm()
    } else toastUtil.error(result.body.message)
  }

  changeAchievement = async () => {
    const data: any = {
      userId: this.userId,
      requestType: this.dataChange.type,
      score: this.dataChange.score,
      itemId: this.dataChange.itemId,
      amount: this.dataChange.amount,
    }
    if (data.amount < 0) {
      toastUtil.warning('Amount must not be null')
      return
    }
    this.isLoadingBt = true
    const result = await userService.changeAchievement(data)
    this.isLoadingBt = false
    if (result.status === HttpStatusCode.OK) {
      $('#changeAchievement_close').trigger('click')
      toastUtil.success('Change Success')
      await this.getUserDetail(this.userId)
      this.clearForm()
    } else toastUtil.error(result.body.message)
  }

  changeExp = async () => {
    const data: any = {
      userId: this.userId,
      requestType: this.dataChange.type,
      resourceType: this.dataChange.resourceType,
      amount: this.dataChange.amount,
      note: this.dataChange.note,
    }
    if (data.amount < 0) {
      toastUtil.warning('Amount must not be null')
      return
    }
    this.isLoadingBt = true
    const result = await userService.changeExp(data)
    this.isLoadingBt = false
    if (result.status === HttpStatusCode.OK) {
      $('#changeExp_close').trigger('click')
      toastUtil.success('Change Success')
      await this.getUserDetail(this.userId)
      this.clearForm()
    } else toastUtil.error(result.body.message)
  }

  changeTicket = async () => {
    const data: any = {
      userId: this.userId,
      requestType: this.dataChange.type,
      resourceType: this.dataChange.resourceType,
      amount: this.dataChange.amount,
      note: this.dataChange.note,
    }
    if (data.amount < 0) {
      toastUtil.warning('Amount must not be null')
      return
    }
    this.isLoadingBt = true
    const result = await userService.changeTicket(data)
    this.isLoadingBt = false
    if (result.status === HttpStatusCode.OK) {
      $('#changeTicket_close').trigger('click')
      toastUtil.success('Change Success')
      await this.getUserDetail(this.userId)
      this.clearForm()
    } else toastUtil.error(result.body.message)
  }

  changeLuckyPoint = async () => {
    const data: any = {
      userId: this.userId,
      requestType: this.dataChange.type,
      resourceType: this.dataChange.resourceType,
      amount: this.dataChange.amount,
      note: this.dataChange.note,
    }
    if (data.amount < 0) {
      toastUtil.warning('Amount must not be null')
      return
    }
    this.isLoadingBt = true
    const result = await userService.changeLuckyPoint(data)
    this.isLoadingBt = false
    if (result.status === HttpStatusCode.OK) {
      $('#changeLuckyPoint_close').trigger('click')
      toastUtil.success('Change Success')
      await this.getUserDetail(this.userId)
      this.clearForm()
    } else toastUtil.error(result.body.message)
  }

  changePointTotal = async () => {
    const data: any = {
      userId: this.userId,
      requestType: this.dataChange.type,
      resourceType: this.dataChange.resourceType,
      amount: this.dataChange.amount,
      note: this.dataChange.note,
      monthId: this.dataChange.monthId || null,
    }
    if (data.amount < 0) {
      toastUtil.warning('Amount must not be null')
      return
    }
    this.isLoadingBt = true
    const result = await userService.changePointTotal(data)
    this.isLoadingBt = false
    if (result.status === HttpStatusCode.OK) {
      $('#changePointTotal_close').trigger('click')
      toastUtil.success('Change Success')
      await this.getUserDetail(this.userId)
      this.clearForm()
    } else toastUtil.error(result.body.message)
  }

  changePackage = async () => {
    const data: any = {
      userId: this.userId,
      serviceId: this.dataPackage.serviceId,
      paymentMethod: this.dataPackage.paymentMethod,
      partner: this.dataPackage.partner,
      type: this.dataPackage.type,
      note: this.dataPackage.note
    }

    this.isLoadingBt = true
    const result = await userService.changePackage(data)
    this.isLoadingBt = false
    if (result.status === HttpStatusCode.OK) {
      $('#changePackage_close').trigger('click')
      toastUtil.success('Change Success')
      await this.getUserDetail(this.userId)
      this.clearForm()
    } else toastUtil.error(result.body.message)
  }

  cancelPackage = async () => {
    const data: any = {
      userId: this.userId,
      id: this.idCancel,
      serviceId: this.dataPackage.serviceId,
      paymentMethod: this.dataPackage.paymentMethod,
      partner: this.dataPackage.partner,
      type: "UNSUBSCRIBE",
      note: this.dataPackage.note
    }

    this.isLoadingBt = true
    const result = await userService.changePackage(data)
    this.isLoadingBt = false
    if (result.status === HttpStatusCode.OK) {
      $('#changePackage_close').trigger('click')
      toastUtil.success('Change Success')
      await this.getUserDetail(this.userId)
      this.clearForm()
    } else toastUtil.error(result.body.message)
  }

}

export const userStore = new UserStore()

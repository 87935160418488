import {Type, userStore} from '../UserStore'
import {observer} from 'mobx-react-lite'
import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import NoContent from '../../../common/components/NoCotent'
import {dateUtils} from '../../../common/utils/DateUtils'
import {profileStore} from '../../../modules/profile/ProfileStore'
import Loading from "../../../common/components/Loading";
import DateRangePicker from "../../../common/components/DateRangePicker";

const CoinTransaction = observer(() => {
  const {id} = useParams()

  useEffect(() => {
    userStore.countPage = 0
    userStore.next = ''
    userStore.pre = ''
    userStore.searchDate = ''
    userStore.getCoinTransaction(id).then()
  }, [])

  const enterSearch = async (e: any) => {
    if (e.key === 'Enter') {
      await searchUser()
    }
  }

  const searchUser = async () => {
    userStore.next = ''
    userStore.pre = ''
    userStore.searchDate = ''
    await userStore.getCoinTransaction(id)
  }

  const handlePage = async (type: any) => {
    userStore.typePagination = type
    if (type === Type.NEXT) {
      userStore.countPage++
      userStore.pre = ''
      userStore.next = userStore.metadata.next
      await userStore.getCoinTransaction(id)
    } else {
      userStore.countPage--
      userStore.pre = userStore.metadata.pre
      userStore.next = ''
      await userStore.getCoinTransaction(id)
    }
  }

  return (
    <div className="collection_user">

      <div className="d-flex justify-content-between align-items-center">
        <DateRangePicker
          default={[userStore.startDate, userStore.endDate]}
          onChange={async (start, end) => {
            if (start !== undefined && end !== undefined) {
              userStore.startDate = dateUtils.getDateString(start)
              userStore.endDate = dateUtils.getDateString(end)
              await userStore.getCoinTransaction(id)
            }
          }}
        />

        {profileStore.profileDetail.role !== 'VIEWER' &&
          <button className="btn btn-sm btn-outline btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#modal_changeCoin"
                  onClick={() => {
                    userStore.clearForm()
                  }}
          >
            Change Coin
          </button>
        }
      </div>

      {userStore.isLoadingGet ? <Loading/> :
        <>
          {userStore.coinTransaction && userStore.coinTransaction.length > 0 ?
            <div className="max-w-full overflow-x-auto">
              <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                <thead>
                <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                  <th>
                    Id
                  </th>
                  <th>
                    Amount
                  </th>
                  <th>
                    Type
                  </th>
                  <th>
                    Balance
                  </th>
                  <th>
                    Note
                  </th>
                  <th>
                    Time
                  </th>
                </tr>
                </thead>
                <tbody>
                {userStore.coinTransaction && userStore.coinTransaction.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        {item.id}
                      </td>
                      <td>
                        {item.amount}
                      </td>
                      <td>
                        {item.type}
                      </td>
                      <td>
                        {item.balance}
                      </td>
                      <td>
                        {item.note}
                      </td>
                      <td>
                        {dateUtils.formatDate(item.createdAt)}
                      </td>
                    </tr>
                  )
                })}
                </tbody>
              </table>
            </div>
            :
            <NoContent/>
          }
        </>
      }

      {userStore.coinTransaction && userStore.coinTransaction.length > 0 &&
        <div className="d-flex justify-content-between align-items-center mt-3">
          <button className="btn btn-sm btn-light-primary"
                  disabled={userStore.countPage == 0 && true}
                  onClick={() => handlePage(Type.PRE)}>
            <i className="fas fa-arrow-left"></i> Pre
          </button>
          <button className="btn btn-sm btn-light-primary"
                  disabled={userStore.coinTransaction && userStore.coinTransaction.length < 10 && true}
                  onClick={() => handlePage(Type.NEXT)}>
            Next <i className="fas fa-arrow-right"></i>
          </button>
        </div>
      }

    </div>
  )
})

export default CoinTransaction

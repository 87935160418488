import React from 'react'
import {accountStore} from '../AccountStore'
import {observer} from 'mobx-react-lite'

const ModalCreate = observer(() => {
  const [show, setShow] = React.useState(false)
  return (
    <div className="modal fade" tabIndex={-1} id="modal_create" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Create</h5>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              id="create_close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fas fa-times" />
            </div>
          </div>
          <div className="modal-body py-lg-10 px-lg-10">
            <div className="mb-5">
              <label className="form-label">DisplayName</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter displayname"
                value={accountStore.accountDetail.displayName}
                onChange={(e) => accountStore.accountDetail.displayName = e.currentTarget.value}
              />
            </div>
            <div className="row">
              <div className="mb-5 col-6">
                <label className="form-label">Username</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter username"
                  value={accountStore.accountDetail.username}
                  onChange={(e) => accountStore.accountDetail.username = e.currentTarget.value}
                />
              </div>
              <div className="mb-5 col-6">
                <label className="form-label">Password</label>
                <div className="d-flex align-items-center justify-content-end ">
                  <input
                    type={show ? 'text' : 'password'}
                    className="form-control"
                    autoComplete="new-password"
                    placeholder="Enter password"
                    value={accountStore.accountDetail.password}
                    onChange={(e) => accountStore.accountDetail.password = e.currentTarget.value}
                  />
                  <span className="position-absolute me-4">
                  <i className={`cursor-pointer fas ${show ? 'fa-eye' : 'fa-eye-slash'}`} onClick={() => {
                    setShow(!show)
                  }}/>
                </span>
                </div>
              </div>
            </div>
            <div className="mb-5">
              <label className="form-label">Role</label>
              <select className="form-select"
                      data-placeholder="Select role"
                      value={accountStore.accountDetail.role}
                      onChange={(e) => accountStore.accountDetail.role = e.currentTarget.value}>
                <option value="">Choose Role</option>
                {accountStore.roles.map((item, i) => {
                  return <option key={i} value={item}>{item}</option>
                })}
              </select>
            </div>
            <div className="mb-5">
              <label className="form-label">Status</label>
              <select className="form-select"
                      data-placeholder="Select role"
                      value={accountStore.accountDetail.state}
                      onChange={(e) => accountStore.accountDetail.state = e.currentTarget.value}>
                <option value="">Choose Role</option>
                <option value="ACTIVATED">ACTIVATED</option>
                <option value="BANNED">BANNED</option>
              </select>
            </div>

          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-sm btn-light"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button type="button" className="btn btn-sm btn-primary" onClick={() => accountStore.create()}>
              {accountStore.isLoadingBt ?
                <span className="">
                  Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
                :
                'Confirm'
              }
            </button>
          </div>
        </div>
      </div>
    </div>
  )
})

export default ModalCreate
import React, {useEffect} from 'react'
import {accountStore} from './AccountStore'
import {observer} from 'mobx-react-lite'
import NoContent from '../../common/components/NoCotent'
import ModalCreate from './components/ModalCreate'
import Paginate from '../../common/components/Paginate'
import ModalEdit from './components/ModalEdit'
import Badge from '../../common/components/Badge'
import Loading from '../../common/components/Loading'
import ModalDelete from './components/ModalDelete'
import {Link} from "react-router-dom";
import ModalQr from "./components/ModalQr";
import {dateUtils} from "../../common/utils/DateUtils";

const AccountWrapper = observer(() => {

  useEffect(() => {
    accountStore.searchName = ''
    accountStore.getList().then()
  }, [])

  const handlePage = (page) => {
    accountStore.page = page.selected
    accountStore.getList().then()
  }

  const search = () => {
    accountStore.page = 0
    accountStore.getList().then()
  }

  return (
    <>

      <div className="app-toolbar d-flex justify-content-between mb-5">
        <div className="app-container d-flex flex-stack container-xxl">
          <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
            <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
              Account
            </h1>
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
              <li className="breadcrumb-item text-muted">
                <Link to={"/"} className="text-muted text-hover-primary">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item">
                <span className="bullet bg-gray-500 w-5px h-2px"></span>
              </li>
              <li className="breadcrumb-item text-muted">
                Account
              </li>
            </ul>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-3">
            <button type="button" onClick={() => accountStore.clearForm()}
                    className="btn btn-sm fw-bold btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#modal_create">
              Create
            </button>
          </div>
        </div>
      </div>

      <div className="app-content flex-column-fluid">
        <div className="app-container container-xxl">
          <div className="card card-flush">
            <div className="card-header align-items-center py-5 gap-2 gap-md-5">
              <div className="card-title">
                <div className="d-flex align-items-center position-relative my-1">
                  <i className="bi bi-search fs-5 position-absolute ms-4" onClick={() => search()}/>
                  <input type="text"
                         id={"account_search"}
                         value={accountStore.searchName}
                         className="form-control form-control-solid w-250px ps-12"
                         placeholder="Enter Username"
                         onChange={(e) => accountStore.searchName = e.currentTarget.value}
                         onKeyDown={(e) => {
                           if (e.key === "Enter") {
                             search()
                           }
                         }}
                  />
                </div>
              </div>
              <div className="card-toolbar flex-row-fluid justify-content-end gap-5">

              </div>
            </div>
            <div className="card-body pt-0">
              {accountStore.isLoading ?
                <Loading/>
                :
                <>
                  {accountStore.accountList && accountStore.accountList.length > 0 ?
                    <div className="table-responsive">
                      <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                        <thead>
                        <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                          <th className="">Id</th>
                          <th className="">DisplayName</th>
                          <th className="">Role</th>
                          <th className="">Username</th>
                          <th className="">Created At</th>
                          <th className="">Updated At</th>
                          <th className="">Status</th>
                          <th className="text-end">Action</th>
                        </tr>
                        </thead>

                        <tbody className="text-gray-600 fw-bold">
                        {accountStore.accountList.map((item: any, i: number) => {
                          return (
                            <tr key={i}>
                              <td className="text-gray-800 fs-5 fw-bold">
                                {item.id}
                              </td>
                              <td className="text-gray-800 fs-5 fw-bold">
                                {item.displayName}
                              </td>
                              <td className="text-gray-800 fs-5 fw-bold">
                                {item.role}
                              </td>
                              <td className="text-gray-800 fs-5 fw-bold">
                                {item.username}
                              </td>
                              <td className="text-gray-800 fs-5 fw-bold">
                                {dateUtils.getDateTimeString(item.createdAt)}
                              </td>
                              <td className="text-gray-800 fs-5 fw-bold">
                                {dateUtils.getDateTimeString(item.updatedAt)}
                              </td>
                              <td className="text-gray-800 fs-5 fw-bold">
                                <Badge status={item.state}/>
                              </td>
                              <td className="d-flex justify-content-end">
                                <button className="btn btn-sm btn-icon btn-primary"
                                        onClick={async () => {
                                          accountStore.qrReset = item.username
                                          accountStore.qrConfirm = ""
                                          await accountStore.genQr(item.username)
                                        }}
                                        data-bs-toggle="modal"
                                        data-bs-target="#modal_qr"
                                        title="Gen Qr"
                                >
                                  <i className="fas fa-qrcode"></i>
                                </button>
                                <button className="btn btn-sm btn-icon btn-warning ms-3"
                                        onClick={() => accountStore.getDetail(item.username)}
                                        data-bs-toggle="modal"
                                        data-bs-target="#modal_edit">
                                  <i className="fas fa-pen"></i>
                                </button>
                                <button className="btn btn-sm btn-icon btn-danger ms-3"
                                        onClick={() => accountStore.id = item.username}
                                        data-bs-toggle="modal"
                                        data-bs-target="#modal_delete">
                                  <i className="fas fa-trash"></i>
                                </button>
                              </td>
                            </tr>
                          )
                        })}
                        </tbody>
                      </table>
                    </div>
                    :
                    <NoContent/>
                  }
                </>
              }
            </div>
          </div>
        </div>

        {accountStore.totalPages > 1 &&
          <Paginate currentPage={accountStore.page}
                    totalPages={accountStore.totalPages}
                    callback={handlePage}
          />
        }
      </div>

      <ModalCreate/>
      <ModalEdit/>
      <ModalDelete/>
      <ModalQr/>

    </>
  )
})

export default AccountWrapper
export default class Constants {
  public static TOKEN_EXPIRE_DAYS: number = 16;
  public static TOKEN_NAME: string = "token";
  public static REFRESH_TOKEN_NAME: string = "refresh-token";
  public static API_URL: String = (window as any).API_DOMAIN;
  public static DATE_FORMAT: any = "yyyy-MM-DD";
  public static GOOGLE_RECAPTCHA_KEY: string = (window as any).GOOGLE_RECAPTCHA_KEY;
}

export enum RoleUser {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  AGENT = 'AGENT'
}

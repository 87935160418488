import React, {FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {ModalWrap} from '../../_metronic/partials/widgets'
import Welcome from "../common/components/Welcome";
import AccountWrapper from "../pages/account/AccountWrapper";
import UserWrapper from '../pages/users/UserWrapper'
import UserDetail from '../pages/users/components/UserDetail'
import LevelWrapper from '../pages/level/LevelWrapper'
import ShopItem from '../pages/shopItem/ShopItem'
import BannerWrapper from '../pages/banner/BannerWrapper'
import GameWrapper from '../pages/gameList/GameWrapper'
import GameCreate from '../pages/gameList/components/GameCreate'
import GameEdit from '../pages/gameList/components/GameEdit'
import GameCategory from '../pages/gameCategory/GameCategory'
import GameType from '../pages/gameType/GameType'
import RewardItemWrapper from '../pages/rewardItem/RewardItemWrapper'
import RewardPoolWrapper from '../pages/rewardPool/RewardPoolWrapper'
import RewardItemStoreWrapper from '../pages/rewardItemStore/RewardItemStoreWrapper'
import ProductType from '../pages/productType/ProductType'
import ProductWrapper from '../pages/product/ProductWrapper'
import ProfileWrapper from "../modules/profile/ProfileWrapper";
import ChallengeWrapper from "../pages/challenge/ChallengeWrapper";
import ChallengeDetail from "../pages/challenge/components/ChallengeDetail";
import CollectionWrapper from "../pages/collection/CollectionWrapper";
import StoreExchange from "../pages/storeExchange/StoreExchange";
import RewardType from "../pages/rewardType/RewardType";
import PoolDetail from "../pages/rewardPool/components/PoolDetail";
import StoreDetail from "../pages/rewardItemStore/components/StoreDetail";
import AchievementWrapper from "../pages/achievement/AchievementWrapper";
import TaskWrapper from "../pages/task/TaskWrapper";
import QuestWrapper from "../pages/quest/QuestWrapper";
import QuestTurnWrapper from "../pages/questTurn/QuestTurnWrapper";
import ConfigWrapper from "../pages/config/ConfigWrapper";
import FeatureWrapper from "../pages/feature/FeatureWrapper";
import SchedulerControl from "../pages/rewardPool/components/SchedulerControl";
import Statistics from "../pages/dashboard/Statistics";
import TransactionWrapper from "../pages/transaction/TransactionWrapper";


const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="auth/*" element={<Navigate to="/welcome" />} />
        <Route path="/" element={<Navigate to="/welcome" />} />

        {/*---------------------------------------------------------------------------*/}
        <Route path="/###" element={<ModalWrap />} />
        {/*---------------------------------------------------------------------------*/}

        <Route path="/welcome" element={<SuspensedView><Welcome /></SuspensedView>} />

        <Route path="/dashboard/statistics" element={<SuspensedView><Statistics /></SuspensedView>} />

        <Route path="/profile" element={<SuspensedView><ProfileWrapper /></SuspensedView>} />
        <Route path="/account" element={<SuspensedView><AccountWrapper /></SuspensedView>} />
        <Route path="/user" element={<SuspensedView><UserWrapper /></SuspensedView>} />
        <Route path="/user/:id" element={<SuspensedView><UserDetail /></SuspensedView>} />

        {/*<Route path="/level" element={<SuspensedView><LevelWrapper /></SuspensedView>} />*/}
        {/*<Route path="/feature" element={<SuspensedView><FeatureWrapper /></SuspensedView>} />*/}
        {/*<Route path="/banner" element={<SuspensedView><BannerWrapper /></SuspensedView>} />*/}
        {/*<Route path="/shop" element={<SuspensedView><ShopItem /></SuspensedView>} />*/}
        {/*<Route path="/storeExchange" element={<SuspensedView><StoreExchange /></SuspensedView>} />*/}
        {/*<Route path="/product/type" element={<SuspensedView><ProductType /></SuspensedView>} />*/}
        <Route path="/product/list" element={<SuspensedView><ProductWrapper /></SuspensedView>} />
        <Route path="/transaction" element={<SuspensedView><TransactionWrapper /></SuspensedView>} />

        {/*<Route path="/game/category" element={<SuspensedView><GameCategory /></SuspensedView>} />*/}
        {/*<Route path="/game/type" element={<SuspensedView><GameType /></SuspensedView>} />*/}
        {/*<Route path="/game/list" element={<SuspensedView><GameWrapper /></SuspensedView>} />*/}
        {/*<Route path="/game/create" element={<SuspensedView><GameCreate /></SuspensedView>} />*/}
        {/*<Route path="/game/edit/:id" element={<SuspensedView><GameEdit /></SuspensedView>} />*/}
        {/*<Route path="/challenge" element={<SuspensedView><ChallengeWrapper /></SuspensedView>} />*/}
        {/*<Route path="/challenge/:id" element={<SuspensedView><ChallengeDetail /></SuspensedView>} />*/}

        {/*<Route path="/reward/type" element={<SuspensedView><RewardType /></SuspensedView>} />*/}
        {/*<Route path="/reward/store" element={<SuspensedView><RewardItemStoreWrapper /></SuspensedView>} />*/}
        {/*<Route path="/reward/store/:id" element={<SuspensedView><StoreDetail /></SuspensedView>} />*/}
        {/*<Route path="/reward/item" element={<SuspensedView><RewardItemWrapper /></SuspensedView>} />*/}
        {/*<Route path="/reward/pool" element={<SuspensedView><RewardPoolWrapper /></SuspensedView>} />*/}
        {/*<Route path="/reward/pool/:id" element={<SuspensedView><PoolDetail /></SuspensedView>} />*/}
        {/*<Route path="/reward/schedule/:id" element={<SuspensedView><SchedulerControl /></SuspensedView>} />*/}

        {/*<Route path="/collection" element={<SuspensedView><CollectionWrapper /></SuspensedView>} />*/}
        {/*<Route path="/achievement" element={<SuspensedView><AchievementWrapper /></SuspensedView>} />*/}
        {/*<Route path="/quest/quest" element={<SuspensedView><QuestWrapper /></SuspensedView>} />*/}
        {/*<Route path="/quest/turn" element={<SuspensedView><QuestTurnWrapper /></SuspensedView>} />*/}
        {/*<Route path="/taskDaily" element={<SuspensedView><TaskWrapper /></SuspensedView>} />*/}
        <Route path="/config" element={<SuspensedView><ConfigWrapper /></SuspensedView>} />

        {/*---------------------------------------------------------------------------*/}

        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })

  // @ts-ignore
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}

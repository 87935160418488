import {observer} from 'mobx-react-lite'
import {transactionStore, Type} from './TransactionStore'
import React, {useEffect, useState} from 'react'
import Loading from '../../common/components/Loading'
import NoContent from '../../common/components/NoCotent'
import {Link} from "react-router-dom";
import {dateUtils} from "../../common/utils/DateUtils";
import DateRangePicker from "../../common/components/DateRangePicker";
import {number_format} from "../../common/utils/Utils";
import Badge from "../../common/components/Badge";

const TransactionWrapper = observer(() => {
  useEffect(() => {
    transactionStore.getList().then()
  }, [])

  const handlePageClick = async (type: any) => {
    transactionStore.typePagination = type
    if (type === Type.NEXT) {
      transactionStore.countPage++
      transactionStore.pre = ''
      transactionStore.next = transactionStore.metadata.next
      await transactionStore.getList()
    } else {
      transactionStore.countPage--
      transactionStore.pre = transactionStore.metadata.pre
      transactionStore.next = ''
      await transactionStore.getList()
    }
  }

  const search = () => {
    transactionStore.next = ''
    transactionStore.pre = ''
    transactionStore.getList().then()
  }

  return <>
    <div className="app-toolbar d-flex justify-content-between mb-5">
      <div className="app-container d-flex flex-stack container-xxl">
        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
          <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
            Transaction
          </h1>
          <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
            <li className="breadcrumb-item text-muted">
              <Link to={"/"} className="text-muted text-hover-primary">
                Home
              </Link>
            </li>
            <li className="breadcrumb-item">
              <span className="bullet bg-gray-500 w-5px h-2px"></span>
            </li>
            <li className="breadcrumb-item text-muted">
              Transaction
            </li>
          </ul>
        </div>
        <div className="d-flex align-items-center gap-2 gap-lg-3">

        </div>
      </div>
    </div>

    <div className="app-content flex-column-fluid">
      <div className="app-container container-xxl">
        <div className="card card-flush">
          <div className="card-header justify-content-between align-items-center py-5 gap-2 gap-md-5 flex-nowrap">
            <div className="card-title">
              <select className="form-select form-select-solid"
                      style={{borderRight: 0, borderBottomRightRadius: 0, borderTopRightRadius: 0}}
                      value={transactionStore.searchType}
                      onChange={(e) => {
                        transactionStore.searchType = e.currentTarget.value
                        transactionStore.searchTranId = ''
                        transactionStore.searchMsisdn = ''
                        transactionStore.searchProduct = ''
                      }}
              >
                <option value="Product Name">Product Name</option>
                <option value="Msisdn">Msisdn</option>
                <option value="Transaction Id">Transaction Id</option>
                <option value="Partner Transaction Id">Partner Transaction Id</option>
              </select>
              <div className="d-flex align-items-center position-relative me-3">
                <i className="bi bi-search fs-5 position-absolute ms-4" onClick={() => search()}/>
                <input type="text"
                       style={{borderBottomLeftRadius: 0, borderTopLeftRadius: 0}}
                       className="form-control w-250px ps-12"
                       value={
                         transactionStore.searchType === "Product Name" ? transactionStore.searchProduct :
                           `${transactionStore.searchType === "Msisdn" ? transactionStore.searchMsisdn :
                             `${transactionStore.searchType === "Transaction Id" ? transactionStore.searchTranId : transactionStore.searchPartnerTranId}`
                           }`
                       }
                       onChange={(e: any) => {
                         if (transactionStore.searchType === "Product Name") {
                           transactionStore.searchProduct = e.currentTarget.value
                         }
                         if (transactionStore.searchType === "Msisdn") {
                           transactionStore.searchMsisdn = e.currentTarget.value
                         }
                         if (transactionStore.searchType === "Transaction Id") {
                           transactionStore.searchTranId = e.currentTarget.value
                         }
                         if (transactionStore.searchType === "Partner Transaction Id") {
                           transactionStore.searchPartnerTranId = e.currentTarget.value
                         }
                       }}
                       onKeyDown={(e) => {
                         if (e.key === 'Enter') search()
                       }}
                       placeholder={`Enter ${transactionStore.searchType}`}
                />
              </div>
              {/*<div className="d-flex align-items-center position-relative me-3">*/}
              {/*  <i className="bi bi-search fs-5 position-absolute ms-4"/>*/}
              {/*  <input type="text"*/}
              {/*         value={transactionStore.searchProduct}*/}
              {/*         className="form-control form-control-solid w-250px ps-12"*/}
              {/*         placeholder="Search by product name"*/}
              {/*         onChange={(e) => transactionStore.searchProduct = e.currentTarget.value}*/}
              {/*  />*/}
              {/*</div>*/}
              <select className="form-select form-select-solid me-3"
                      value={transactionStore.searchChargeType}
                      onChange={(e) => transactionStore.searchChargeType = e.currentTarget.value}
              >
                <option value={""}>Choose ChargeType</option>
                <option value="RENEW">RENEW</option>
                <option value="REGISTER">REGISTER</option>
                <option value="UNSUBSCRIBE">UNSUBSCRIBE</option>
                <option value="CHARGE">CHARGE</option>
              </select>
              <select className="form-select form-select-solid me-3"
                      value={transactionStore.searchState}
                      onChange={(e) => transactionStore.searchState = e.currentTarget.value}
              >
                <option value={""}>Choose Status</option>
                <option value="PAID">PAID</option>
                <option value="COMPLETE">COMPLETE</option>
              </select>
              <div className="d-flex align-items-center">
                <DateRangePicker
                  default={[transactionStore.startDate, transactionStore.endDate]}
                  onChange={async (start, end) => {
                    if (start !== undefined && end !== undefined) {
                      console.log(start, end)
                      transactionStore.startDate = dateUtils.getDateString(start)
                      transactionStore.endDate = dateUtils.getDateString(end)
                      // await transactionStore.getList()
                    }
                  }}
                />
              </div>
            </div>
            <div className="card-toolbar flex-row-fluid align-items-start justify-content-end">
              <button type="button" onClick={() => transactionStore.export()}
                      className="btn btn-sm fw-bold btn-primary">
                {transactionStore.isLoadingBt ?
                  <span className="spinner-border spinner-border-sm align-middle"></span> : "Export"}
              </button>
            </div>
          </div>

          <div className="ps-10 d-flex align-items-center justify-content-start">
            <button type="reset"
                    className="btn btn-sm btn-light btn-active-light-primary me-2"
                    onClick={() => transactionStore.clearForm()}>
              Reset
            </button>
            <button type="submit" className="btn btn-sm btn-primary me-2"
                    onClick={() => search()}>
              Apply
            </button>
          </div>

          <div className="card-body pt-0">
            {transactionStore.isLoading ?
              <Loading/>
              :
              <>
                {transactionStore.transactionList && transactionStore.transactionList.length > 0 ?
                  <div className="table-responsive">
                    <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                      <thead>
                      <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                        <th>
                          Msisdn
                        </th>
                        <th>
                          Payment Transaction Id
                        </th>
                        <th>
                          Product Id
                        </th>
                        <th>
                          Product Name
                        </th>
                        <th>
                          Price
                        </th>
                        <th>
                          Partner
                        </th>
                        <th>
                          Method
                        </th>
                        <th>
                          Charge Type
                        </th>
                        <th>
                          Charge Status
                        </th>
                        <th>
                          Send MT
                        </th>
                        <th>
                          CreatedAt
                        </th>
                      </tr>
                      </thead>

                      <tbody className="text-gray-600 fw-bold">
                      {transactionStore.transactionList.map((item: any, i: number) => {
                        return (
                          <tr key={i}>
                            <td className="text-gray-800 fs-5 fw-bold">
                              {item.phoneNumber}
                            </td>
                            <td className="text-gray-800 fs-5 fw-bold">
                              {item.paymentTransactionId}
                            </td>
                            <td className="text-gray-800 fs-5 fw-bold">
                              {item.product.id} ({item.product.serviceId})
                            </td>
                            <td className="text-gray-800 fs-5 fw-bold">
                              {item.product.name}
                            </td>
                            <td className="text-gray-800 fs-5 fw-bold">
                              {number_format(item.product.price)}
                            </td>
                            <td className="text-gray-800 fs-5 fw-bold">
                              {item.partner}
                            </td>
                            <td className="text-gray-800 fs-5 fw-bold">
                              {item.paymentMethod}
                            </td>
                            <td className="text-gray-800 fs-5 fw-bold">
                              {item.chargeType}
                            </td>
                            <td className="text-gray-800 fs-5 fw-bold">
                              <Badge status={item.state}/>
                            </td>
                            <td className="text-gray-800 fs-5 fw-bold">
                              {item.isSendMt ? <i className="fas fa-check text-success"/> :
                                <i className="fas fa-times text-danger"/>}
                            </td>
                            <td className="text-gray-800 fs-5 fw-bold">
                              {dateUtils.getDateTimeString(item.createdAt)}
                            </td>
                          </tr>
                        )
                      })}
                      </tbody>

                    </table>
                  </div>
                  :
                  <NoContent/>
                }
              </>
            }
          </div>
        </div>
      </div>

      {transactionStore.transactionList && transactionStore.transactionList.length > 0 &&
        <div className="d-flex justify-content-between align-items-center mt-3">
          <button className="btn btn-sm btn-light-primary"
                  disabled={transactionStore.countPage === 0 && true}
                  onClick={() => handlePageClick(Type.PRE)}>
            <i className="fas fa-arrow-left"></i> Pre
          </button>
          <button className="btn btn-sm btn-light-primary"
                  disabled={transactionStore.transactionList && transactionStore.transactionList.length < 10 && true}
                  onClick={() => handlePageClick(Type.NEXT)}>
            Next <i className="fas fa-arrow-right"></i>
          </button>
        </div>
      }

    </div>

  </>
})

export default TransactionWrapper

import {Link} from 'react-router-dom'
import {goBack} from '../utils/Utils'
import React from 'react'

interface BreadcrumbProps {
  pageName: string;
  backLink?: string;
  back?: boolean;
  id?: string;
  name?: string
}

const Breadcrumb = (props: BreadcrumbProps) => {
  return (
    <div className="mb-5 d-flex align-items-center">
      <div className="d-flex">
        {props.backLink &&
          <Link to={props.backLink}>
            <button className="btn btn-sm">
              <i className="bi bi-arrow-left"></i>
            </button>
          </Link>
        }
        {props.back &&
          <button className="btn btn-sm" onClick={() => goBack()}>
            <i className="bi bi-arrow-left"></i>
          </button>
        }
      </div>
      <nav>
        <ol className="breadcrumb text-muted fs-6 fw-bold">
          {/*<li className="breadcrumb-item pe-3">*/}
          {/*  <Link className="pe-3" to="/">Home</Link>*/}
          {/*</li>*/}
          <li className="breadcrumb-item px-3 text-muted">{props.pageName} {props.id && "- #" + props.id} {props.name && "- " + props.name}</li>
        </ol>
      </nav>
    </div>
  )
}

export default Breadcrumb

import React from 'react'

const ModalWrap = () => {
  return (
    <>

    </>
  )
}

export {ModalWrap}

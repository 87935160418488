import {observer} from 'mobx-react-lite'
import {productStore} from './ProductStore'
import React, {useEffect} from 'react'
import Loading from '../../common/components/Loading'
import NoContent from '../../common/components/NoCotent'
import Paginate from '../../common/components/Paginate'
import {profileStore} from '../../modules/profile/ProfileStore'
import Badge from '../../common/components/Badge'
import ModalCreate from './components/ModalCreate'
import ModalEdit from './components/ModalEdit'
import ModalDelete from './components/ModalDelete'
import ChangeQuantity from './components/ChangeQuantity'
import {Link} from "react-router-dom";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {number_format} from "../../common/utils/Utils";

const ProductWrapper = observer(() => {
  useEffect(() => {
    productStore.getProduct().then()
  }, [])

  const handlePage = (page) => {
    productStore.page = page.selected
    productStore.getProduct().then()
  }

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return
    }
    console.log(`dragEnd ${result.source.index} to  ${result.destination.index}`);
    productStore.reorder(
      result.source.index,
      result.destination.index
    );
    productStore.isChanged = true;
  }

  return <>
    <div className="app-toolbar d-flex justify-content-between mb-5">
      <div className="app-container d-flex flex-stack container-xxl">
        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
          <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
            Product
          </h1>
          <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
            <li className="breadcrumb-item text-muted">
              <Link to={"/"} className="text-muted text-hover-primary">
                Home
              </Link>
            </li>
            <li className="breadcrumb-item">
              <span className="bullet bg-gray-500 w-5px h-2px"></span>
            </li>
            <li className="breadcrumb-item text-muted">
              Product
            </li>
          </ul>
        </div>
        <div className="d-flex align-items-center gap-2 gap-lg-3">
          <div className="m-0">
            {/*<button className="btn btn-sm btn-flex fw-bold bg-body btn-color-gray-700 btn-active-color-primary"*/}
            {/*        data-kt-menu-trigger="click"*/}
            {/*        data-kt-menu-placement="bottom-end">*/}
            {/*  <i className="fas fa-filter">*/}
            {/*  </i>Filter*/}
            {/*</button>*/}
            <div className="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true">
              <div className="px-7 py-5">
                <div className="fs-5 text-gray-900 fw-bolder">Filter Options</div>
              </div>
              <div className="separator border-gray-200"></div>
              <div className="px-7 py-5">
                <div className="mb-10"><label className="form-label fw-bold">Status:</label>
                  <div><select className="form-select form-select-solid" data-kt-select2="true"
                               data-placeholder="Select option" data-allow-clear="true">
                    <option></option>
                    <option value="1" selected={true}>Approved</option>
                    <option value="2">Pending</option>
                    <option value="3">In Process</option>
                    <option value="4">Rejected</option>
                  </select></div>
                </div>
                <div className="mb-10"><label className="form-label fw-bold">Member Type:</label>
                  <div className="d-flex"><label
                    className="form-check form-check-sm form-check-custom form-check-solid me-5"><input
                    className="form-check-input" type="checkbox" value="1"/><span
                    className="form-check-label">Author</span></label><label
                    className="form-check form-check-sm form-check-custom form-check-solid"><input
                    className="form-check-input" type="checkbox" value="2" checked={true}/><span
                    className="form-check-label">Customer</span></label></div>
                </div>
                <div className="mb-10"><label className="form-label fw-bold">Notifications:</label>
                  <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid"><input
                    className="form-check-input" type="checkbox" name="notifications" value="" checked={true}/><label
                    className="form-check-label">Enabled</label></div>
                </div>
                <div className="d-flex justify-content-end">
                  <button type="reset" className="btn btn-sm btn-light btn-active-light-primary me-2"
                          data-kt-menu-dismiss="true">Reset
                  </button>
                  <button type="submit" className="btn btn-sm btn-primary" data-kt-menu-dismiss="true">Apply</button>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <button type="button" onClick={() => productStore.public()}
                    className="btn btn-sm fw-bold btn-info me-2"
            >
              {productStore.isLoadingBt ?
                <span className="spinner-border spinner-border-sm align-middle"></span>
                :
                'Publish'
              }
            </button>
            <button type="button" onClick={() => productStore.clearForm()}
                    className="btn btn-sm fw-bold btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#modal_create">
              Create
            </button>
          </div>
        </div>
      </div>
    </div>

    <div className="app-content flex-column-fluid">
      <div className="app-container container-xxl">
        <div className="card card-flush">
          {/*<div className="card-header align-items-center py-5 gap-2 gap-md-5">*/}
          {/*  <div className="card-title">*/}

          {/*  </div>*/}
          {/*  <div className="card-toolbar flex-row-fluid justify-content-end gap-5">*/}

          {/*  </div>*/}
          {/*</div>*/}
          <div className="card-body pt-10">
            {productStore.isLoading ?
              <Loading/>
              :
              <>
                {productStore.listProduct && productStore.listProduct.length > 0 ?
                  <TableContainer className="p-4" component={Paper}>
                    <Table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                      <TableHead>
                        <TableRow className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                          <TableCell> Sort</TableCell>
                          <TableCell> ID</TableCell>
                          <TableCell> Name</TableCell>
                          <TableCell> Service Id</TableCell>
                          <TableCell> Type</TableCell>
                          <TableCell> Platform</TableCell>
                          <TableCell> Price</TableCell>
                          <TableCell> Category</TableCell>
                          <TableCell> Sms</TableCell>
                          <TableCell> Msisdn</TableCell>
                          <TableCell> Status</TableCell>
                          {profileStore.profileDetail.role !== 'VIEWER' &&
                            <TableCell className="text-end"> Action </TableCell>
                          }
                        </TableRow>
                      </TableHead>
                      <TableBody className="fw-semibold text-gray-600" component={DroppableComponent(onDragEnd)}>
                        {productStore.listProduct.map((item: any, i: number) => {
                          return (
                            <TableRow component={DraggableComponent(item.id.toString(), i)}
                                      key={item.id}>
                              <TableCell className="text-gray-800 fs-5 fw-bold">
                                {item.position}
                              </TableCell>
                              <TableCell className="text-gray-800 fs-5 fw-bold">
                                {item.id}
                              </TableCell>
                              <TableCell className="text-gray-800 fs-5 fw-bold">
                                {item.name}
                              </TableCell>
                              <TableCell className="text-gray-800 fs-5 fw-bold">
                                {item.serviceId}
                              </TableCell>
                              <TableCell className="text-gray-800 fs-5 fw-bold">
                                {item.type}
                              </TableCell>
                              <TableCell className="text-gray-800 fs-5 fw-bold">
                                {item.platform}
                              </TableCell>
                              <TableCell className="text-gray-800 fs-5 fw-bold">
                                {number_format(item.price)}
                              </TableCell>
                              <TableCell className="text-gray-800 fs-5 fw-bold">
                                {item.category}
                              </TableCell>
                              <TableCell className="text-gray-800 fs-5 fw-bold">
                                {item.smsBody}
                              </TableCell>
                              <TableCell className="text-gray-800 fs-5 fw-bold">
                                {item.smsPhone}
                              </TableCell>
                              <TableCell className="text-gray-800 fs-5 fw-bold">
                                <Badge status={item.state}/>
                              </TableCell>
                              {profileStore.profileDetail.role !== 'VIEWER' &&
                                <TableCell className="text-end">
                                  {/*<button className="btn btn-sm btn-icon btn-primary me-3"*/}
                                  {/*        data-bs-toggle="modal"*/}
                                  {/*        data-bs-target="#modal_change"*/}
                                  {/*        onClick={async () => {*/}
                                  {/*          productStore.clearForm()*/}
                                  {/*          productStore.dataQuantity.id = item.id*/}
                                  {/*        }}>*/}
                                  {/*  <i className="fas fa-exchange-alt"/>*/}
                                  {/*</button>*/}
                                  <button className="btn btn-sm btn-icon btn-warning me-3"
                                          data-bs-toggle="modal"
                                          data-bs-target="#modal_edit"
                                          onClick={async () => {
                                            await productStore.detailProduct(item.id)
                                          }}>
                                    <i className="fas fa-pen"/>
                                  </button>
                                  <button className="btn btn-sm btn-icon btn-danger"
                                          data-bs-toggle="modal"
                                          data-bs-target="#modal_delete"
                                          onClick={() => {
                                            productStore.id = item.id
                                          }}>
                                    <i className="fas fa-trash"/>
                                  </button>
                                </TableCell>
                              }
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  :
                  <NoContent/>
                }
              </>
            }
          </div>
        </div>
      </div>

    </div>

    <ModalCreate/>
    <ModalEdit/>
    <ModalDelete/>
    <ChangeQuantity/>

  </>
})

export default ProductWrapper

// @ts-ignore
const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: "rgb(235,235,235)",
    padding: "0 10px 0 10px"
  })
});

const DraggableComponent = (id: string, index: number) => (props: any) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <TableRow
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}

          {...props}
        >
          {props.children}
        </TableRow>
      )}
    </Draggable>
  )
};
const DroppableComponent = (
  onDragEnd: (result: any, provided: any) => void) => (props: any) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={'1'} direction="vertical">
        {(provided) => {
          return (
            <TableBody ref={provided.innerRef} {...provided.droppableProps} {...props}>
              {props.children}
              {provided.placeholder}
            </TableBody>
          )
        }}
      </Droppable>
    </DragDropContext>
  )
};
/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {data} from './MenuList'
import {profileStore} from '../../../../app/modules/profile/ProfileStore'

export function AsideMenuMain() {
  const intl = useIntl()

  const role = profileStore.profileDetail.role
  const itemByRoles = data.filter((index: any) => {
    return (index.role === role)
  })
  const menuList = itemByRoles[0].menu

  return (
    <>

      {menuList.map((item: any, itemKey: number) => {
        return <div key={itemKey}>
          {item.label &&
            <div className="menu-item">
              <div className="menu-content pt-8 pb-2">
                <span className="menu-section text-muted text-uppercase ls-1">{item.label}</span>
              </div>
            </div>
          }
          {item.items.map((list: any, listKey: number) => {
            return <div key={listKey}>
              {!list.subMenu ?
                <AsideMenuItem
                  to={list.link}
                  icon={list.icon}
                  title={list.title}
                  fontIcon="bi-app-indicator"
                  hasBullet={item.label}
                />
                :
                <AsideMenuItemWithSub
                  to={list.link}
                  icon={list.icon}
                  title={list.title}
                  hasBullet={item.label}>
                  {list.subMenu.map((child: any, childKey: number) => {
                    return <AsideMenuItem key={childKey}
                                          to={child.link}
                                          title={child.title}
                                          hasBullet={true}
                    />
                  })}
                </AsideMenuItemWithSub>
              }
            </div>
          })}
        </div>
      })}
    </>
  )
}

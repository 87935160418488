import {action, makeAutoObservable} from "mobx";
import {dateUtils} from "../../common/utils/DateUtils";
import {getRequest} from "../../common/helpers/RequestHelper";
import HttpStatusCode from "../../common/constants/HttpErrorCode";
import {toastUtil} from "../../common/utils/ToastUtil";


class DashboardStore {
  isLoading: boolean = false;
  public date: any = new Date();
  dataReport: any;
  dataTotal: any = []
  dataPayment: any = []
  dataTable: any = []
  total: any = {
    label: '',
    totalMps: 0,
    totalPurchase: 0,
    totalRegister: 0,
    totalRenew: 0,
    totalCharge: 0,
    totalUnsub: 0,
    totalRevenue: 0,
    totalSms: 0,
    totalUssd: 0,
    totalRegisterRevenue: 0,
    totalRenewRevenue: 0,
  }
  endDate: any = new Date();
  startDate: any = new Date(new Date().getFullYear(), new Date().getMonth(), 1)

  constructor() {
    makeAutoObservable(this,{
      getStatic: action,
    })
  }

  async getStatic() {
    this.dataTotal = []
    this.dataPayment = []
    this.dataTable = []
    let startDate = dateUtils.getDateString(this.startDate);
    let endDate = dateUtils.getDateString(this.endDate);
    this.isLoading = true;
    const response = await getRequest(`/v1/delta/portal/statistic/payment?startDate=${startDate}&endDate=${endDate}`)
    this.isLoading = false;
    if (response.status === HttpStatusCode.OK) {
      this.dataReport = response.body
      if (this.dataReport) {
        for (let i = 0; i < this.dataReport.length; i++) {
          let item = this.dataReport[i];
          this.dataPayment.push(item.payments)
          item.payments.forEach((payments) => {
            this.total.label = payments.productName
            this.total.totalMps += payments.totalMps
            this.total.totalPurchase += payments.totalPurchase
            this.total.totalRegister += payments.totalRegister
            this.total.totalRenew += payments.totalRenew
            this.total.totalCharge += payments.totalCharge
            this.total.totalUnsub += payments.totalUnsub
            this.total.totalRevenue += payments.totalRevenue
            this.total.totalSms += payments.totalSms
            this.total.totalUssd += payments.totalUssd
            this.total.totalRegisterRevenue += payments.totalRegisterRevenue
            this.total.totalRenewRevenue += payments.totalRenewRevenue
          })
          this.dataTotal.push(this.total)
          this.total = {
            label: '',
            totalMps: 0,
            totalPurchase: 0,
            totalRegister: 0,
            totalRenew: 0,
            totalCharge: 0,
            totalUnsub: 0,
            totalRevenue: 0,
            totalSms: 0,
            totalUssd: 0,
            totalRegisterRevenue: 0,
            totalRenewRevenue: 0,
          }
        }
      }

      for (let i = 0; i < this.dataPayment[0].length; i++) {
        this.dataPayment.map((item) => {
          this.dataTable.push(item[i])
        })
      }

    } else {
      toastUtil.error(response.body.message)
    }
  }


}

export const dashboardStore = new DashboardStore();
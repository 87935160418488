import React, {useEffect} from 'react'
import {dashboardStore} from './DashboardStore'
import Loading from '../../common/components/Loading'
import DateRangePicker from '../../common/components/DateRangePicker'
import {dateUtils} from '../../common/utils/DateUtils'
import DataComponent from './DataComponent'
import ChartStatic from '../../common/components/ChartStatic'
import {observer} from 'mobx-react-lite'
import {Link} from "react-router-dom";
import {number_format} from "../../common/utils/Utils";

const Statistics = observer(() => {
  useEffect(() => {
    dashboardStore.getStatic().then()
  }, [])

  return (
    <>
      <div className="app-toolbar d-flex justify-content-between mb-5">
        <div className="app-container d-flex flex-stack container-xxl">
          <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
            <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
              Statistics
            </h1>
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
              <li className="breadcrumb-item text-muted">
                <Link to={"/"} className="text-muted text-hover-primary">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item">
                <span className="bullet bg-gray-500 w-5px h-2px"></span>
              </li>
              <li className="breadcrumb-item text-muted">
                Dashboard
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="app-content flex-column-fluid">
        <div className="app-container container-xxl">
          <div className="card card-flush">
            <div className="card-header align-items-center py-5 gap-2 gap-md-5">
              <div className="card-title">
                <div className="d-flex align-items-center my-1">
                  <DateRangePicker
                    default={[dashboardStore.startDate, dashboardStore.endDate]}
                    onChange={async (start, end) => {
                      if (start !== undefined && end !== undefined) {
                        console.log(start, end)
                        dashboardStore.startDate = dateUtils.getDateString(start)
                        dashboardStore.endDate = dateUtils.getDateString(end)
                        await dashboardStore.getStatic()
                      }
                    }}
                  />
                </div>
              </div>
              {/*<div className="card-toolbar flex-row-fluid justify-content-end gap-5">*/}
              {/*  <button className="btn btn-sm btn-primary ms-3"*/}
              {/*          onClick={() => dashboardStore.exportStatistics()}*/}
              {/*  >*/}
              {/*    {dashboardStore.isLoadingBt ?*/}
              {/*      <span className="spinner-border spinner-border-sm align-middle ms-2"></span> : "Export"}*/}
              {/*  </button>*/}
              {/*</div>*/}
            </div>
            <div className="card-body pt-0">
              {dashboardStore.isLoading ?
                <Loading/>
                :
                <div>
                  {/*<DataComponent type="statistic"/>*/}
                  <ChartStatic dataStatistic={dashboardStore.dataReport} type={'statistic'}/>
                  {dashboardStore.dataTable && dashboardStore.dataTable.length > 0 &&
                    <div className="table-responsive mt-5">
                      <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                        <thead>
                        <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                          <th className=""><strong>Date</strong></th>
                          <th className=""><strong>Product</strong></th>
                          <th className=""><strong>Register</strong></th>
                          <th className=""><strong>Renew</strong></th>
                          <th className=""><strong>Charge</strong></th>
                          <th className=""><strong>Mps</strong></th>
                          <th className=""><strong>Sms</strong></th>
                          <th className=""><strong>Ussd</strong></th>
                          <th className=""><strong>Total</strong></th>
                          <th className=""><strong>Register Revenue</strong></th>
                          <th className=""><strong>Renew Revenue</strong></th>
                          <th className=""><strong>Revenue</strong></th>
                          <th className=""><strong>Unsub</strong></th>
                        </tr>
                        </thead>
                        <tbody className="text-gray-600 fw-bold">
                        {dashboardStore.dataTable.map((child, index) => {
                          return (
                            <tr key={index}>
                              <td
                                className="text-gray-800 fs-5 fw-bold">{index % dashboardStore.dataPayment.length == 0 && child.daily}</td>
                              <td className="text-gray-800 fs-5 fw-bold">{child.productName}</td>
                              <td className="text-gray-800 fs-5 fw-bold">
                                {number_format(child.totalRegister)}
                              </td>
                              <td className="text-gray-800 fs-5 fw-bold">
                                {number_format(child.totalRenew)}
                              </td>
                              <td className="text-gray-800 fs-5 fw-bold">
                                {number_format(child.totalCharge)}</td>
                              <td className="text-gray-800 fs-5 fw-bold">
                                {number_format(child.totalMps)}
                              </td>
                              <td className="text-gray-800 fs-5 fw-bold">
                                {number_format(child.totalSms)}
                              </td>
                              <td className="text-gray-800 fs-5 fw-bold">
                                {number_format(child.totalUssd)}
                              </td>
                              <td className="text-gray-800 fs-5 fw-bold">
                                {number_format(child.totalPurchase)}
                              </td>
                              <td className="text-gray-800 fs-5 fw-bold">
                                {number_format(child.totalRegisterRevenue)}
                              </td>
                              <td className="text-gray-800 fs-5 fw-bold">
                                {number_format(child.totalRenewRevenue)}
                              </td>
                              <td className="text-gray-800 fs-5 fw-bold">
                                {number_format(child.totalRevenue)}
                              </td>
                              <td className="text-gray-800 fs-5 fw-bold">
                                {number_format(child.totalUnsub)}
                              </td>
                            </tr>
                          )
                        })
                        }
                        </tbody>
                      </table>
                    </div>
                  }
                </div>
              }

            </div>
          </div>
        </div>
      </div>
    </>
  )
})

export default Statistics

import {deleteRequest, getRequest, IApiResponse, postRequest, putRequest} from '../../common/helpers/RequestHelper'
import {accountStore} from './AccountStore'

class AccountService {
  public getList(): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/account?size=20&page=${accountStore.page}${accountStore.searchName ? `&username=${accountStore.searchName}` : ''}`);
  }

  public getDetail(id: any): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/account/${id}`);
  }

  public create(data: any): Promise<IApiResponse> {
    return postRequest(`/v1/delta/portal/account`, data);
  }

  public update(data: any, id: any): Promise<IApiResponse> {
    return putRequest(`/v1/delta/portal/account/${id}`, data);
  }

  public delete(id: any): Promise<IApiResponse> {
    return deleteRequest(`/v1/delta/portal/account/${id}`, {});
  }

  public genQr(userName: any): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/account/qr/${userName}`);
  }

  public resetQr(userName: any): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/account/reset-salt/${userName}`);
  }

}

export const accountService = new AccountService()
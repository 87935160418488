import {action, makeAutoObservable} from 'mobx'
import {accountService} from "./AccountService";
import HttpStatusCode from "../../common/constants/HttpErrorCode";
import {toastUtil} from '../../common/utils/ToastUtil'
import $ from 'jquery'

class AccountStore {
  public isLoading: boolean = false
  public isLoadingBt: boolean = false
  public isLoadingModal: boolean = false
  public accountList: any = []
  public page: number = 0
  public totalPages: number = 0
  public accountDetail: any = {
    username: '',
    displayName: '',
    password: '',
    role: '',
    state: '',
    accountState: ''
  }
  public id: any = ''
  public roles: any = ['ADMIN', 'OPERATOR', 'AGENT']
  public qr: any = ''
  public qrReset: string = ''
  public qrConfirm: string = ''
  public searchName: string = ''

  constructor() {
    makeAutoObservable(this, {
      getList: action,
      getDetail: action,
      clearForm: action,
      create: action,
      update: action,
      delete: action
    })
  }

  clearForm() {
    this.accountDetail = {
      username: '',
      displayName: '',
      password: '',
      role: '',
      state: '',
      accountState: ''
    }
  }

  getList = async () => {
    this.isLoading = true
    const result = await accountService.getList()
    this.isLoading = false
    if (result.status === HttpStatusCode.OK) {
      this.accountList = result.body.data;
      this.totalPages = result.body.metadata.totalPages
    } else {
      toastUtil.error(result.body.message)
    }
  }

  getDetail = async (id: any) => {
    const result = await accountService.getDetail(id)
    if (result.status === HttpStatusCode.OK) {
      this.accountDetail = result.body;
    } else {
      toastUtil.error(result.body.message)
    }
  }

  create = async () => {
    const data = this.accountDetail
    this.isLoadingBt = true
    const result = await accountService.create(data)
    this.isLoadingBt = false
    if (result.status === HttpStatusCode.OK) {
      $('#create_close').trigger('click')
      toastUtil.success("Created")
      await this.getList()
    } else {
      toastUtil.error(result.body.message)
    }
  }

  update = async () => {
    const data = this.accountDetail
    this.isLoadingBt = true
    const result = await accountService.update(data, data.username)
    this.isLoadingBt = false
    if (result.status === HttpStatusCode.OK) {
      $('#edit_close').trigger('click')
      toastUtil.success("Created")
      await this.getList()
    } else {
      toastUtil.error(result.body.message)
    }
  }

  delete = async () => {
    this.isLoadingBt = true
    const result = await accountService.delete(this.id)
    this.isLoadingBt = false
    if (result.status === HttpStatusCode.OK) {
      $('#delete_close').trigger('click')
      toastUtil.success("Deleted")
      await this.getList()
    } else {
      toastUtil.error(result.body.message)
    }
  }

  async genQr(userName: any) {
    this.isLoadingModal = true
    const result = await accountService.genQr(userName);
    this.isLoadingModal = false
    if (result.status === HttpStatusCode.OK) {
      this.qr = result.body
    } else {
      toastUtil.error(result.body.message ? result.body.message : 'Get false.');
    }
  }

  async resetQr() {
    if (this.qrReset !== this.qrConfirm) {
      toastUtil.warning('Wrong Username !!!');
      return
    } else {
      this.isLoadingBt = true
      const result = await accountService.resetQr(this.qrReset);
      this.isLoadingBt = false
      if (result.status === HttpStatusCode.OK) {
        toastUtil.success("Resetted")
        $('#qr_close').trigger('click');
      } else {
        toastUtil.error(result.body.message);
      }
    }
  }

}

export const accountStore = new AccountStore()
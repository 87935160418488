import {Type, userStore} from '../UserStore'
import {observer} from 'mobx-react-lite'
import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import NoContent from '../../../common/components/NoCotent'
import {dateUtils} from '../../../common/utils/DateUtils'
import Loading from "../../../common/components/Loading";
import Badge from "../../../common/components/Badge";

const PackageUser = observer(() => {
  const {id} = useParams()

  useEffect(() => {
    userStore.countPage = 0
    userStore.next = ''
    userStore.pre = ''
    userStore.searchProductId = ''
    userStore.searchProductName = ''
    userStore.searchType = 'Product Id'
    userStore.getUserPackage(id).then()
  }, [])

  const search = async () => {
    userStore.countPage = 0
    userStore.next = ''
    userStore.pre = ''
    await userStore.getUserPackage(id)
  }

  const handlePage = async (type: any) => {
    userStore.typePagination = type
    if (type === Type.NEXT) {
      userStore.countPage++
      userStore.pre = ''
      userStore.next = userStore.metadata.next
      await userStore.getUserPackage(id)
    } else {
      userStore.countPage--
      userStore.pre = userStore.metadata.pre
      userStore.next = ''
      await userStore.getUserPackage(id)
    }
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <div className="d-flex align-items-center">
          <select className="form-select form-select-solid"
                  style={{borderRight: 0, borderBottomRightRadius: 0, borderTopRightRadius: 0}}
                  value={userStore.searchType}
                  onChange={(e) => {
                    userStore.searchType = e.currentTarget.value
                    userStore.searchProductId = ''
                    userStore.searchProductName = ''
                  }}
          >
            <option value="Product Id">Product Id</option>
            <option value="Product Name">Product Name</option>
          </select>
          <div className="d-flex align-items-center position-relative my-1">
            <i className="bi bi-search fs-5 position-absolute ms-4" onClick={() => search()}/>
            <input type="text"
                   style={{borderBottomLeftRadius: 0, borderTopLeftRadius: 0}}
                   className="form-control w-250px ps-12"
                   value={
                     userStore.searchType === "Product Id" ? userStore.searchProductId :
                       `${userStore.searchType === "Product Name" ? userStore.searchProductName : userStore.searchProductName}`
                   }
                   onChange={(e: any) => {
                     if (userStore.searchType === "Product Id") {
                       userStore.searchProductId = e.currentTarget.value
                     }
                     if (userStore.searchType === "Product Name") {
                       userStore.searchProductName = e.currentTarget.value
                     }
                   }}
                   onKeyDown={(e) => {
                     if (e.key === 'Enter') search().then()
                   }}
                   placeholder={`Enter ${userStore.searchType}`}
            />
          </div>
        </div>

        {/*{profileStore.profileDetail.role !== 'VIEWER' &&*/}
        {/*  <button className="btn btn-sm btn-outline btn-primary"*/}
        {/*          data-bs-toggle="modal"*/}
        {/*          data-bs-target="#modal_changePackage"*/}
        {/*          onClick={() => {*/}
        {/*            userStore.clearForm()*/}
        {/*            userStore.getProduct().the*/}
        {/*          }}*/}
        {/*  >*/}
        {/*    Add Package*/}
        {/*  </button>*/}
        {/*}*/}
      </div>

      {userStore.isLoadingGet ? <Loading/> :
        <>
          {userStore.userPackage && userStore.userPackage.length > 0 ?
            <div className="max-w-full overflow-x-auto">
              <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                <thead>
                <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                  <th>Product Id</th>
                  <th>Product Name</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  <th>Status</th>
                </tr>
                </thead>
                <tbody>
                {userStore.userPackage && userStore.userPackage.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{item.productId}</td>
                      <td>{item.productName}</td>
                      <td>{dateUtils.formatDate(item.createdAt)}</td>
                      <td>{dateUtils.formatDate(item.updatedAt)}</td>
                      <td>
                        <Badge status={item.state}/>
                      </td>
                    </tr>
                  )
                })}
                </tbody>
              </table>
            </div>
            :
            <NoContent/>
          }
        </>
      }

      {userStore.userPackage && userStore.userPackage.length > 0 &&
        <div className="d-flex justify-content-between align-items-center mt-3">
          <button className="btn btn-sm btn-light-primary"
                  disabled={userStore.countPage == 0 && true}
                  onClick={() => handlePage(Type.PRE)}>
            <i className="fas fa-arrow-left"></i> Pre
          </button>
          <button className="btn btn-sm btn-light-primary"
                  disabled={userStore.userPackage && userStore.userPackage.length < 10 && true}
                  onClick={() => handlePage(Type.NEXT)}>
            Next <i className="fas fa-arrow-right"></i>
          </button>
        </div>
      }

    </>
  )
})

export default PackageUser

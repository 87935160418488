const Welcome = (props: any) => {
  return (
    <div className="d-flex justify-content-center align-items-center py-5">
      <div className="text-black text-center fs-5">
        {props.message ? props.message : "Welcome"}
      </div>
    </div>
  );
};

export default Welcome;

import {Type, userStore} from '../UserStore'
import {observer} from 'mobx-react-lite'
import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import NoContent from '../../../common/components/NoCotent'
import {dateUtils} from '../../../common/utils/DateUtils'
import Loading from "../../../common/components/Loading";
import DateRangePicker from "../../../common/components/DateRangePicker";
import {number_format} from "../../../common/utils/Utils";

const CharacterHistory = observer(() => {
  const {id} = useParams()

  useEffect(() => {
    userStore.countPage = 0
    userStore.next = ''
    userStore.pre = ''
    userStore.searchDate = ''
    userStore.getCharacterHistory(id).then()
  }, [])

  const enterSearch = async (e: any) => {
    if (e.key === 'Enter') {
      await searchUser()
    }
  }

  const searchUser = async () => {
    userStore.next = ''
    userStore.pre = ''
    userStore.searchDate = ''
    await userStore.getCharacterHistory(id)
  }

  const handlePage = async (type: any) => {
    userStore.typePagination = type
    if (type === Type.NEXT) {
      userStore.countPage++
      userStore.pre = ''
      userStore.next = userStore.metadata.next
      await userStore.getCharacterHistory(id)
    } else {
      userStore.countPage--
      userStore.pre = userStore.metadata.pre
      userStore.next = ''
      await userStore.getCharacterHistory(id)
    }
  }

  return (
    <div className="collection_user">

      <div className="d-flex justify-content-between align-items-center">
        <DateRangePicker
          default={[userStore.startDate, userStore.endDate]}
          onChange={async (start, end) => {
            if (start !== undefined && end !== undefined) {
              userStore.startDate = dateUtils.getDateString(start)
              userStore.endDate = dateUtils.getDateString(end)
              await userStore.getCharacterHistory(id)
            }
          }}
        />

        {/*{profileStore.profileDetail.role !== 'VIEWER' &&*/}
        {/*  <button className="btn btn-sm btn-outline btn-primary"*/}
        {/*          data-bs-toggle="modal"*/}
        {/*          data-bs-target="#modal_changeSpin"*/}
        {/*          onClick={() => {*/}
        {/*            userStore.clearForm()*/}
        {/*          }}*/}
        {/*  >*/}
        {/*    Change Run*/}
        {/*  </button>*/}
        {/*}*/}
      </div>

      {userStore.isLoadingGet ? <Loading/> :
        <>
          {userStore.characterHistory && userStore.characterHistory.length > 0 ?
            <div className="max-w-full overflow-x-auto">
              <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                <thead>
                <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                  <th>Id</th>
                  <th>Name</th>
                  <th>Product Type</th>
                  <th>Point Price</th>
                  <th>Coin Price</th>
                  <th>Note</th>
                  <th>Time</th>
                </tr>
                </thead>
                <tbody>
                {userStore.characterHistory && userStore.characterHistory.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{item.characterId}</td>
                      <td>{item.name}</td>
                      <td>{item.resourceType}</td>
                      <td>{number_format(item.pointPrice)}</td>
                      <td>{number_format(item.coinPrice)}</td>
                      <td>{item.note}</td>
                      <td>{dateUtils.formatDate(item.createdAt)}</td>
                    </tr>
                  )
                })}
                </tbody>
              </table>
            </div>
            :
            <NoContent/>
          }
        </>
      }

      {userStore.characterHistory && userStore.characterHistory.length > 0 &&
        <div className="d-flex justify-content-between align-items-center mt-3">
          <button className="btn btn-sm btn-light-primary"
                  disabled={userStore.countPage == 0 && true}
                  onClick={() => handlePage(Type.PRE)}>
            <i className="fas fa-arrow-left"></i> Pre
          </button>
          <button className="btn btn-sm btn-light-primary"
                  disabled={userStore.characterHistory && userStore.characterHistory.length < 10 && true}
                  onClick={() => handlePage(Type.NEXT)}>
            Next <i className="fas fa-arrow-right"></i>
          </button>
        </div>
      }

    </div>
  )
})

export default CharacterHistory

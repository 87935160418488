import React from 'react'
import {observer} from 'mobx-react-lite'
import {userStore} from '../UserStore'

const ChangeChatacter = observer(() => {
  return (
    <div className="modal fade" tabIndex={-1} id="modal_changeCharacter" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Change</h5>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              id="changeCharacter_close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fas fa-times"/>
            </div>
          </div>
          <div className="modal-body py-lg-10 px-lg-10">
            <div className="mb-5">
              <label className="form-label">Type</label>
              <select className="form-select"
                      data-placeholder="Select role"
                      value={userStore.dataChange.type}
                      onChange={(e) => userStore.dataChange.type = e.currentTarget.value}>
                <option value="ADD">ADD</option>
                <option value="SUBTRACT">SUBTRACT</option>
              </select>
            </div>
            <div className="mb-5">
              <label className="form-label">Character</label>
              <select className="form-select"
                      data-placeholder="Select role"
                      value={userStore.dataChange.itemId}
                      onChange={(e) => userStore.dataChange.itemId = e.currentTarget.value}>
                <option value="">Choose Character</option>
                {userStore.characterList && userStore.characterList.map((item, i) => {
                  return <option key={i} value={item.id}>{item.name}</option>
                })}
              </select>
            </div>
            <div className="mb-5">
              <label className="form-label">Amount</label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter amount"
                value={userStore.dataChange.amount}
                onChange={(e) => userStore.dataChange.amount = e.currentTarget.value}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-sm btn-light"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button type="button" className="btn btn-sm btn-primary" onClick={() => userStore.changeCharacter()}>
              {userStore.isLoadingBt ?
                <span className="">
                  Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
                :
                'Confirm'
              }
            </button>
          </div>
        </div>
      </div>
    </div>
  )
})

export default ChangeChatacter
import {Bounce, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

class ToastUtil {
  
  public info(message: string) {
    toast.info(message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    })
  }
  
  public success(message: string, id?: any) {
    toast.success(message, {
      toastId: id,
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    })
  }
  
  public warning(message: string, id?: any) {
    toast.warn(message, {
      toastId: id,
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    })
  }
  
  public error(message: string, id?: any) {
    toast.error(message ?? "Đã có lỗi xảy ra!", {
      toastId: id,
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    })
  }
  
}

export const toastUtil = new ToastUtil();


import {observer} from "mobx-react-lite";
import {profileStore} from "./ProfileStore";
import React, {useEffect} from "react";

const ProfileWrapper = observer(() => {

  useEffect(() => {
    profileStore.tabActive = "profile"
  }, []);

  const [show, setShow] = React.useState(false)
  const [showConfirm, setShowConfirm] = React.useState(false)

  const filterTab = (type: string) => {
    profileStore.tabActive = type
  }


  return <>

    <div className="d-flex overflow-auto h-55px mb-5">
      <ul
        className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-7 fw-bolder flex-nowrap">
        <li className="nav-item">
          <button
            className={`nav-link text-active-primary me-6 ${profileStore.tabActive === "profile" && 'active'}`}
            onClick={() => filterTab("profile")}>Info
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link text-active-primary me-6 ${profileStore.tabActive === "changePass" && 'active'}`}
            onClick={() => filterTab("changePass")}>Change Password
          </button>
        </li>
      </ul>
    </div>

    {profileStore.tabActive === "profile" &&
      <div className="d-flex flex-wrap align-items-center flex-sm-nowrap mb-3">
        <div className="me-7 mb-4">
          <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative"><img
            src="/media/avatars/300-1.jpg" alt="Metronic"/>
            <div
              className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div>
          </div>
        </div>
        <div className="flex-grow-1">
          <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center mb-4">
                <a href="#"
                   className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                  {profileStore.profileDetail.displayName}</a>
              </div>
              <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                <a href="#"
                   className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                  Username: {profileStore.profileDetail.username}
                </a>
              </div>
              <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                <a href="#"
                   className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                  Role: {profileStore.profileDetail.role}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    }

    {profileStore.tabActive === "changePass" &&
      <>
        <div className="row">
          <div className="col-6 mb-5">
            <label className="form-label"> New Password</label>
            <div className="d-flex align-items-center justify-content-end ">
              <input
                type={show ? "text" : "password"}
                className="form-control"
                placeholder="Enter new password"
                value={profileStore.dataProfile.passWord}
                onChange={(e) => profileStore.dataProfile.passWord = e.currentTarget.value}
              />
              <span className="position-absolute me-4">
                  <i className={`cursor-pointer fas ${show ? "fa-eye" : "fa-eye-slash"}`} onClick={() => {
                    setShow(!show)
                  }}/>
                </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 mb-5">
            <label className="form-label"> Confirm Password</label>
            <div className="d-flex align-items-center justify-content-end ">
              <input
                type={showConfirm ? "text" : "password"}
                className="form-control"
                placeholder="Enter confirm password"
                value={profileStore.dataProfile.confirmPassword}
                onChange={(e) => profileStore.dataProfile.confirmPassword = e.currentTarget.value}
              />
              <span className="position-absolute me-4">
                  <i className={`cursor-pointer fas ${showConfirm ? "fa-eye" : "fa-eye-slash"}`} onClick={() => {
                    setShowConfirm(!showConfirm)
                  }}/>
                </span>
            </div>
          </div>
        </div>
        <div className="d-flex flex-wrap align-items-center">
          <button className="btn btn-sm btn-primary" onClick={() => profileStore.changePassword()}>
            {profileStore.isLoadingBt ?
              <span className="">
                  Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span> : "Save"}
          </button>
        </div>
      </>
    }

  </>
})

export default ProfileWrapper
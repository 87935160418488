import {makeAutoObservable} from 'mobx'
import {profileService} from './ProfileService'
import HttpStatusCode from '../../common/constants/HttpErrorCode'
import {toastUtil} from "../../common/utils/ToastUtil";

class ProfileStore {
  profileDetail: any = []
  dataProfile: any = {
    passWord: '',
    confirmPassword: ''
  }
  isLoading: boolean = false
  isLoadingBt: boolean = false
  tabActive: string = "profile"

  constructor() {
    makeAutoObservable(this)
  }

  async getProfile() {
    this.isLoading = true
    const result = await profileService.getProfile()
    this.isLoading = false
    if (result.status === HttpStatusCode.OK) {
      this.profileDetail = result.body
    } else {
      this.profileDetail = null
    }
  }

  async changePassword() {
    const {passWord, confirmPassword} = this.dataProfile;
    if (!passWord) {
      toastUtil.warning('Please enter password.');
    } else if (confirmPassword !== passWord) {
      toastUtil.warning('Password and confirmPassword not match.  ');
    } else {
      this.isLoading = true
      const data: any = {
        oldPassword: passWord,
        newPassword: confirmPassword,
      }
      const res = await profileService.changePass(data);
      this.isLoading = false
      if (res.status === HttpStatusCode.OK) {
        toastUtil.success('Change password success');
        this.dataProfile.password = '';
        this.dataProfile.confirmPassword = '';
      } else {
        toastUtil.error(res.body.message ? res.body.message : 'Change password false.');
      }
    }
  }

}

export const profileStore = new ProfileStore()

import {getRequest, IApiResponse, putRequest} from '../../common/helpers/RequestHelper'

class ProfileService {

  public getProfile(): Promise<IApiResponse>{
    return getRequest(`/v1/delta/portal/account/profile`)
  }

  public changePass(data: any): Promise<IApiResponse> {
    return putRequest(`/v1/delta/portal/account/changePassword`, data)
  }

}

export const profileService = new ProfileService();
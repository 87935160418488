import {deleteRequest, getRequest, IApiResponse, postRequest, putRequest} from "../../common/helpers/RequestHelper";
import {productStore} from './ProductStore'
import {uploadFile} from "../../common/helpers/UploadHelper";

class ProductService {
  public getProduct(): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/products?page=${productStore.page}&size=10&`);
  }

  public getDetailProduct(id: number): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/products/${id}`);
  }

  public addProduct(data: any): Promise<IApiResponse> {
    return postRequest(`/v1/delta/portal/products`, data);
  }

  public updateProduct(id: number, data: any): Promise<IApiResponse> {
    return putRequest(`/v1/delta/portal/products/${id}`, data);
  }

  public deleteProduct(id: number): Promise<IApiResponse> {
    return deleteRequest(`/v1/delta/portal/products/${id}`, {id});
  }

  public changeQuantity(data: any): Promise<IApiResponse> {
    return putRequest(`/v1/delta/portal/products/updateQuantity`, data);
  }

  public upload(formData: any): Promise<IApiResponse> {
    return uploadFile(`/v1/delta/portal/images/upload/static`, formData);
  }

  public public(data: any): Promise<IApiResponse> {
    return postRequest(`/v1/delta/portal/products/publish`, {ids: data});
  }

}

export const productService = new ProductService();

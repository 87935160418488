/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import Constants from "../common/constants/Constants";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */


const AppRoutes: FC = () => {
  const {currentUser} = useAuth()
  return (
    <BrowserRouter>
      <GoogleReCaptchaProvider
        reCaptchaKey={Constants.GOOGLE_RECAPTCHA_KEY}
        language="en-GB"
        useRecaptchaNet={true}
        useEnterprise={false}
        scriptProps={{
          async: false, // optional, default to false,
          defer: false, // optional, default to false
          appendTo: 'head', // optional, default to "head", can be "head" or "body",
          nonce: undefined // optional, default undefined
        }}
      >
      <Routes>
          <Route path="error/*" element={<ErrorsPage />} />
          <Route path="logout" element={<Logout />} />
          {currentUser ? (
            <Route path="/*" element={<PrivateRoutes />} />
          ) : (
            <>
              <Route path="auth/" element={<AuthPage />} />
              <Route path="*" element={<Navigate to="/auth" />} />
            </>
          )}
      </Routes>
      </GoogleReCaptchaProvider>
    </BrowserRouter>
  )
}

export {AppRoutes}

import React from 'react'
import {accountStore} from '../AccountStore'
import {observer} from 'mobx-react-lite'
import Loading from "../../../common/components/Loading";

const ModalQr = observer(() => {
  return (
    <div className="modal fade" tabIndex={-1} id="modal_qr" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">TWO FACTOR AUTHENTICATION</h5>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              id="qr_close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fas fa-times"/>
            </div>
          </div>

          <div className="modal-body py-lg-5 px-lg-10 d-flex justify-content-center align-items-center flex-column">
            <span>Scan this QR code from an authenticator application</span>
            {accountStore.isLoadingModal ?
              <Loading/>
              :
              <>
                {accountStore.qr ?
                  <img src={(accountStore.qr)} style={{width: 200, height: 200}}/>
                  :
                  <img className="no_img" style={{width: 200, height: 200}}/>
                }
              </>
            }
          </div>
          <div className="modal-footer d-flex justify-content-center align-items-center flex-column">
            <span>Type the Username of the account to config reset QR</span>
            <div className="w-100 d-flex align-items-center">
              <input type="text" placeholder="Enter Username"
                     className="form-control me-2"
                     value={accountStore.qrConfirm}
                     onChange={(e) => accountStore.qrConfirm = e.currentTarget.value}
              />
              <button className="btn btn-danger ms-2"
                      onClick={() => accountStore.resetQr()}
              >
                {accountStore.isLoadingBt ?
                  <span className="spinner-border spinner-border-sm align-middle"></span>
                  :
                  'Reset'
                }
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default ModalQr
import ReactPaginate from 'react-paginate'
import {observer} from 'mobx-react-lite'

const Paginate = observer((props: any) => {
  return (

    <ReactPaginate
      breakLabel={
        <span className="mr-3">...</span>
      }
      previousLabel={
        <li className="page-item previous disabled">
          <a href="#" className="page-link">
            <i className="previous"></i>
          </a>
        </li>
      }
      nextLabel={
        <li className="page-item next">
          <a href="#" className="page-link">
            <i className="next"></i>
          </a>
        </li>
      }
      renderOnZeroPageCount={null}
      onPageChange={props.callback}
      pageRangeDisplayed={5}
      pageCount={props.totalPages}
      initialPage={props.currentPage}
      containerClassName="d-flex justify-items-center justify-content-center mt-8 mb-4 gap-3 pagination"
      pageClassName="page-item"
      pageLinkClassName="page-link"
      activeClassName="active"
      activeLinkClassName="text-white"
    />
  )
})

export default Paginate

import {exportData, getRequest, IApiResponse, postRequest, putRequest} from "../../common/helpers/RequestHelper";
import {userStore} from "./UserStore";

class UserService {
  
  public getUser(): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/user?${userStore.next ? `&next=${userStore.next}` : ''}${userStore.pre ? `&pre=${userStore.pre}` : ''}${userStore.searchPhone ? `&phoneNumber=${userStore.searchPhone.trim()}` : ''}${userStore.searchExternalId ? `&externalUserId=${userStore.searchExternalId.trim()}` : ''}${userStore.displayName ? `&displayName=${userStore.displayName}` : ''}${userStore.searchId ? `&userId=${userStore.searchId.trim()}` : ''}${userStore.searchName ? `&username=${userStore.searchName}` : ''}${userStore.searchLevel ? `&level=${userStore.searchLevel.trim()}` : ''}${userStore.searchSub ? `&type=${userStore.searchSub.trim()}` : ''}`);
  }

  public userDetail(id: any): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/user/${id}`);
  }

  public userPackage(id: any): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/user/${id}/subs?limit=10${userStore.next ? `&next=${userStore.next}` : ''}${userStore.pre ? `&pre=${userStore.pre}` : ''}${userStore.startDate ? `&gte=${userStore.startDate}` : ''}${userStore.endDate ? `&lte=${userStore.endDate}` : ''}${userStore.searchProductId ? `&productId=${userStore.searchProductId}` : ''}${userStore.searchProductName ? `&productName=${userStore.searchProductName}` : ''}`);
  }

  public userTransaction(id: any): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/user/${id}/transactions?limit=10${userStore.next ? `&next=${userStore.next}` : ''}${userStore.pre ? `&pre=${userStore.pre}` : ''}${userStore.startDate ? `&gte=${userStore.startDate}` : ''}${userStore.endDate ? `&lte=${userStore.endDate}` : ''}${userStore.searchProductId ? `&productId=${userStore.searchProductId}` : ''}${userStore.searchProductName ? `&productName=${userStore.searchProductName}` : ''}${userStore.searchTranId ? `&transId=${userStore.searchTranId}` : ''}${userStore.searchState ? `&state=${userStore.searchState}` : ''}`);
  }

  public characterHistory(id: any): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/user/transaction/user-character?userId=${id}&limit=10${userStore.next ? `&next=${userStore.next}` : ''}${userStore.pre ? `&pre=${userStore.pre}` : ''}${userStore.startDate ? `&gte=${userStore.startDate}` : ''}${userStore.endDate ? `&lte=${userStore.endDate}` : ''}`);
  }

  public characterUser(id: any): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/user/user-character?userId=${id}&limit=10${userStore.next ? `&next=${userStore.next}` : ''}${userStore.pre ? `&pre=${userStore.pre}` : ''}${userStore.startDate ? `&gte=${userStore.startDate}` : ''}${userStore.endDate ? `&lte=${userStore.endDate}` : ''}`);
  }
  
  public getSubHistory(id: any): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/user/sub?userId=${id}&limit=10${userStore.next ? `&next=${userStore.next}` : ''}${userStore.pre ? `&pre=${userStore.pre}` : ''}${userStore.startDate ? `&gte=${userStore.startDate}` : ''}${userStore.endDate ? `&lte=${userStore.endDate}` : ''}`);
  }

  public coinTransaction(id: any): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/user/transaction/coin?userId=${id}&limit=10${userStore.next ? `&next=${userStore.next}` : ''}${userStore.pre ? `&pre=${userStore.pre}` : ''}${userStore.startDate ? `&gte=${userStore.startDate}` : ''}${userStore.endDate ? `&lte=${userStore.endDate}` : ''}`);
  }

  public pointTransaction(id: any): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/user/transaction/point?userId=${id}&limit=10${userStore.next ? `&next=${userStore.next}` : ''}${userStore.pre ? `&pre=${userStore.pre}` : ''}${userStore.startDate ? `&gte=${userStore.startDate}` : ''}${userStore.endDate ? `&lte=${userStore.endDate}` : ''}`);
  }

  public expTransaction(id: any): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/user/transaction/exp?userId=${id}&limit=10${userStore.next ? `&next=${userStore.next}` : ''}${userStore.pre ? `&pre=${userStore.pre}` : ''}${userStore.startDate ? `&gte=${userStore.startDate}` : ''}${userStore.endDate ? `&lte=${userStore.endDate}` : ''}`);
  }

  public spinTransaction(id: any): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/user/transaction/turn?userId=${id}&limit=10${userStore.next ? `&next=${userStore.next}` : ''}${userStore.pre ? `&pre=${userStore.pre}` : ''}${userStore.startDate ? `&gte=${userStore.startDate}` : ''}${userStore.endDate ? `&lte=${userStore.endDate}` : ''}`);
  }

  public ticketTransaction(id: any): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/user/ticket-history?userId=${id}&limit=10${userStore.next ? `&next=${userStore.next}` : ''}${userStore.pre ? `&pre=${userStore.pre}` : ''}${userStore.startDate ? `&gte=${userStore.startDate}` : ''}${userStore.endDate ? `&lte=${userStore.endDate}` : ''}`);
  }

  public rewardHistory(id: any): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/user/reward?userId=${id}&limit=10${userStore.next ? `&next=${userStore.next}` : ''}${userStore.pre ? `&pre=${userStore.pre}` : ''}${userStore.startDate ? `&gte=${userStore.startDate}` : ''}${userStore.endDate ? `&lte=${userStore.endDate}` : ''}`);
  }

  public achievementUser(id: any): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/user/user-achievement?userId=${id}&limit=10${userStore.next ? `&next=${userStore.next}` : ''}${userStore.pre ? `&pre=${userStore.pre}` : ''}${userStore.startDate ? `&gte=${userStore.startDate}` : ''}${userStore.endDate ? `&lte=${userStore.endDate}` : ''}`);
  }

  public collectionHistory(id: any): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/user/transaction/collection?userId=${id}&limit=10${userStore.next ? `&next=${userStore.next}` : ''}${userStore.pre ? `&pre=${userStore.pre}` : ''}${userStore.startDate ? `&gte=${userStore.startDate}` : ''}${userStore.endDate ? `&lte=${userStore.endDate}` : ''}`);
  }

  public collectionUser(id: any): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/user/user-collection?userId=${id}&limit=10${userStore.next ? `&next=${userStore.next}` : ''}${userStore.pre ? `&pre=${userStore.pre}` : ''}${userStore.startDate ? `&gte=${userStore.startDate}` : ''}${userStore.endDate ? `&lte=${userStore.endDate}` : ''}`);
  }

  public luckyPointUser(id: any): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/user/transaction/lucky-point?userId=${id}&limit=10${userStore.next ? `&next=${userStore.next}` : ''}${userStore.pre ? `&pre=${userStore.pre}` : ''}${userStore.startDate ? `&gte=${userStore.startDate}` : ''}${userStore.endDate ? `&lte=${userStore.endDate}` : ''}`);
  }

  public questTransaction(id: any): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/user/quest-history?userId=${id}&limit=10${userStore.next ? `&next=${userStore.next}` : ''}${userStore.pre ? `&pre=${userStore.pre}` : ''}${userStore.startDate ? `&gte=${userStore.startDate}` : ''}${userStore.endDate ? `&lte=${userStore.endDate}` : ''}`);
  }

  public goldPig(id: any): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/user/gold-pig?userId=${id}&limit=10${userStore.next ? `&next=${userStore.next}` : ''}${userStore.pre ? `&pre=${userStore.pre}` : ''}${userStore.startDate ? `&gte=${userStore.startDate}` : ''}${userStore.endDate ? `&lte=${userStore.endDate}` : ''}`);
  }

  public taskDaily(id: any): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/user/task?userId=${id}&limit=10${userStore.next ? `&next=${userStore.next}` : ''}${userStore.pre ? `&pre=${userStore.pre}` : ''}${userStore.startDate ? `&gte=${userStore.startDate}` : ''}${userStore.endDate ? `&lte=${userStore.endDate}` : ''}`);
  }

  public exportCashHistory(id: any, gte: any, lte: any): Promise<IApiResponse> {
    return exportData(`/v1/delta/portal/user/cashHistory/export?gteDate=${gte}&lteDate=${lte}`, `CashHistory_${gte}-${lte}`);
  }

  public changeSpin(data: any): Promise<IApiResponse> {
    return putRequest(`/v1/delta/portal/user/change/turn`, data);
  }

  public changePoint(data: any): Promise<IApiResponse> {
    return putRequest(`/v1/delta/portal/user/change/point`, data);
  }

  public changePointGoldPig(data: any): Promise<IApiResponse> {
    return putRequest(`/v1/delta/portal/user/change/gold-pig`, data);
  }

  public changeCoin(data: any): Promise<IApiResponse> {
    return putRequest(`/v1/delta/portal/user/change/coin`, data);
  }

  public changeExp(data: any): Promise<IApiResponse> {
    return putRequest(`/v1/delta/portal/user/change/exp`, data);
  }

  public changeTicket(data: any): Promise<IApiResponse> {
    return putRequest(`/v1/delta/portal/user/change/ticket`, data);
  }

  public changeTask(data: any): Promise<IApiResponse> {
    return putRequest(`/v1/delta/portal/user/change/task`, data);
  }

  public changeLuckyPoint(data: any): Promise<IApiResponse> {
    return putRequest(`/v1/delta/portal/user/change/lucky-point`, data);
  }

  public changeSub(data: any): Promise<IApiResponse> {
    return putRequest(`/v1/delta/portal/user/change/sub`, data);
  }

  public cancelSub(id: any): Promise<IApiResponse> {
    return putRequest(`/v1/delta/portal/user/sub/cancel?id=${id}`, {});
  }

  public changeCollection(data: any): Promise<IApiResponse> {
    return putRequest(`/v1/delta/portal/user/change/collection`, data);
  }

  public changeCharacter(data: any): Promise<IApiResponse> {
    return putRequest(`/v1/delta/portal/user/change/character`, data);
  }

  public changeAchievement(data: any): Promise<IApiResponse> {
    return putRequest(`/v1/delta/portal/user/change/achievement`, data);
  }

  public changePointTotal(data: any): Promise<IApiResponse> {
    return putRequest(`/v1/delta/portal/user/totalPoint`, data);
  }

  public changePackage(data: any): Promise<IApiResponse> {
    return postRequest(`/v1/delta/portal/user/add/package`, data);
  }

  public getProduct(): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/products`);
  }

  public getAchievement(): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/achievement/active`);
  }

  public getCollection(): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/collection/all`);
  }

  public getTask(): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/task/all`);
  }

  public getCharacter(): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/character/active`);
  }

}

export const userService = new UserService();

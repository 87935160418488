import {action, makeAutoObservable} from "mobx";
import {toastUtil} from "../../common/utils/ToastUtil";
import HttpStatusCode from "../../common/constants/HttpErrorCode";
import {transactionService} from './TransactionService'
import {dateUtils} from "../../common/utils/DateUtils";

export enum Type {
  FIRST = 'FIRST',
  NEXT = 'NEXT',
  PRE = 'PRE'
}

class TransactionStore {
  id: any = ''
  page: any = 0
  key: any = ''
  totalPages: number = 0
  isLoading: boolean = false
  isLoadingBt: boolean = false
  next: any = ''
  pre: any = ''
  metadata: any = []
  typePagination: any = Type.FIRST
  countPage: number = 0
  transactionList: any = []
  startDate: any = dateUtils.getDateString(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000))
  endDate: any = dateUtils.getDateString(new Date())

  searchType: string = 'Product Name'
  searchId: string = ''
  searchTranId: string = ''
  searchPartnerTranId: string = ''
  searchMsisdn: string = ''
  searchProduct: string = ''
  searchState: string = ''
  searchChargeType: string = ''

  constructor() {
    makeAutoObservable(this, {
      getList: action,
      export: action,
      clearForm: action,
    })
  }

  clearForm() {
    this.searchId = ''
    this.searchChargeType = ''
  }

  getList = async () => {
    this.isLoading = true
    const result = await transactionService.getProduct(dateUtils.getDateString(this.startDate),dateUtils.getDateString(this.endDate))
    this.isLoading = false
    if (result.status === HttpStatusCode.OK) {
      this.transactionList = result.body.data
      this.metadata = result.body.metadata
    }
  }

  export = async () => {
    this.isLoadingBt = true
    const result = await transactionService.export()
    this.isLoadingBt = false
    if (result.status === HttpStatusCode.OK) {
      toastUtil.success("Exported")
    } else {
      toastUtil.error(result.body.message)
    }
  }

}

export const transactionStore = new TransactionStore();
import axios from 'axios'
import {AuthModel} from './_models'
import Constants from '../../../common/constants/Constants'

const API_URL = Constants.API_URL

export const LOGIN_URL = `${API_URL}/v1/delta/portal/auth/signin?sign=`
export const REGISTER_URL = `${API_URL}/v1/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/v1/auth/forgot_password`

// Server should return AuthModel
export function login(username: string, password: string, code: string, sign: string) {
  return axios.post<AuthModel>(LOGIN_URL + sign, {
    username,
    password,
    code
  })
}

// Server should return AuthModel
export function register(
  username: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    username,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

import {deleteRequest, getRequest, IApiResponse, postRequest, putRequest} from "../../common/helpers/RequestHelper";
import {bannerStore} from "./BannerStore";
import {uploadFile} from '../../common/helpers/UploadHelper'

class BannerService {
  public getList(): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/banner?type=${bannerStore.bannerType}&page=${bannerStore.page}&size=10`);
  }
  
  public getgetDetail(id: number): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/banner/${id}`);
  }
  
  public create(data: any): Promise<IApiResponse> {
    return postRequest(`/v1/delta/portal/banner`, data);
  }
  
  public update(data: any): Promise<IApiResponse> {
    return putRequest(`/v1/delta/portal/banner`, data);
  }
  
  public delete(id: number): Promise<IApiResponse> {
    return deleteRequest(`/v1/delta/portal/banner/${id}`, {id});
  }


  public upload(formData: any): Promise<IApiResponse> {
    return uploadFile(`/v1/delta/portal/images/upload/static`, formData);
  }


}

export const bannerService = new BannerService();

import {action, makeAutoObservable} from "mobx";
import {toastUtil} from "../../common/utils/ToastUtil";
import HttpStatusCode from "../../common/constants/HttpErrorCode";
import {productService} from './ProductService'
import $ from 'jquery'

class ProductStore {
  id: any = ''
  page: any = 0
  key: any = ''
  totalPages: number = 0
  isLoading: boolean = false
  isLoadingBt: boolean = false
  isLoadingDetail: boolean = false
  isAction: boolean = false
  dataRequest: any =
    {
      id: '',
      serviceId: '',
      spinAmount: 0,
      platform: '',
      name: '',
      price: 0,
      type: '',
      category: '',
      sms: {
        body: '',
        phoneNumber: ''
      },
      note: '',
      imageUrl: '',
      createdAt: '',
      updatedAt: '',
      file: '',
      sort: 0,
      paymentMethod: '',
      state: '',
      description: '',
      loyaltyPackageCode: '',
      partner: '',
    }
  dataQuantity: any = {
    id: '',
    amount: '',
    type: 'ADD'
  }
  listProduct: any[] = []
  listProductActive: any[] = []
  listProductCash: any[] = []
  listProductSelect: any[] = []
  isChanged: boolean = false;

  constructor() {
    makeAutoObservable(this, {
      clearForm: action,
      getProduct: action,
      detailProduct: action,
      create: action,
      update: action,
      delete: action,
    })
  }

  clearForm() {
    this.dataRequest = {
      id: '',
      serviceId: '',
      spinAmount: 0,
      platform: '',
      name: '',
      price: 0,
      type: '',
      category: '',
      sms: {
        body: '',
        phoneNumber: ''
      },
      smsBody: '',
      smsPhone: '',
      note: '',
      imageUrl: '',
      createdAt: '',
      updatedAt: '',
      file: '',
      sort: 0,
      paymentMethod: '',
      state: '',
      description: '',
      loyaltyPackageCode: '',
      partner: '',
    }
    this.dataQuantity = {
      id: '',
      amount: '',
      type: 'ADD'
    }
  }

  getProduct = async () => {
    this.isLoading = true
    const result = await productService.getProduct()
    this.isLoading = false
    if (result.status === HttpStatusCode.OK) {
      this.listProduct = result.body
    }
  }

  detailProduct = async (id: any) => {
    this.isLoadingDetail = true
    const result = await productService.getDetailProduct(id)
    this.isLoadingDetail = false
    if (result.status === HttpStatusCode.OK) {
      this.dataRequest = result.body
    } else {
      toastUtil.error(result.body.message)
    }
  }

  create = async () => {
    const data: any = {
      serviceId: this.dataRequest.serviceId,
      platform: this.dataRequest.platform,
      name: this.dataRequest.name,
      price: this.dataRequest.price,
      type: this.dataRequest.type,
      category: this.dataRequest.category,
      smsBody: this.dataRequest.smsBody,
      smsPhone: this.dataRequest.smsPhone,
      imageUrl: this.dataRequest.imageUrl,
      paymentMethod: this.dataRequest.paymentMethod,
      state: this.dataRequest.state,
      description: this.dataRequest.description,
      partner: this.dataRequest.partner,
    }

    this.isLoadingBt = true;
    const result = await productService.addProduct(data);
    this.isLoadingBt = false;
    if (result.status === HttpStatusCode.OK) {
      $('#create_close').trigger('click')
      toastUtil.success('Createproduct success')
      this.clearForm()
      await this.getProduct()
    } else toastUtil.error(result.body.message)
  }

  update = async () => {
    const data: any = {
      id: this.dataRequest.id,
      serviceId: this.dataRequest.serviceId,
      platform: this.dataRequest.platform,
      name: this.dataRequest.name,
      price: this.dataRequest.price,
      type: this.dataRequest.type,
      category: this.dataRequest.category,
      smsBody: this.dataRequest.smsBody,
      smsPhone: this.dataRequest.smsPhone,
      imageUrl: this.dataRequest.imageUrl,
      paymentMethod: this.dataRequest.paymentMethod,
      state: this.dataRequest.state,
      description: this.dataRequest.description,
      partner: this.dataRequest.partner,
    }

    this.isLoadingBt = true;
    const result = await productService.updateProduct(data.id, data);
    this.isLoadingBt = false;
    if (result.status === HttpStatusCode.OK) {
      $('#edit_close').trigger('click')
      toastUtil.success('Update product success');
      this.clearForm()
      await this.getProduct()
    } else toastUtil.error(result.body.message)
  }

  delete = async () => {
    this.isLoadingBt = true;
    const result = await productService.deleteProduct(this.id);
    this.isLoadingBt = false;
    if (result.status === HttpStatusCode.OK) {
      $('#delete_close').trigger('click')
      toastUtil.success('Delete product success')
      await this.getProduct()
    } else toastUtil.error(result.body.message)
  }

  changeQuantity = async () => {
    let data: any = this.dataQuantity
    if (!data.amount) {
      toastUtil.warning("Trường amount không được để trống")
      return
    }
    this.isLoadingBt = true;
    const result = await productService.changeQuantity(data);
    this.isLoadingBt = false;
    if (result.status === HttpStatusCode.OK) {
      $('#change_close').trigger('click')
      await this.getProduct()
    } else toastUtil.error(result.body.message)
  }

  uploadIcon = async (type: string) => {
    var formData = new FormData()
    const file = this.dataRequest.file
    formData.append('image_url', file)
    const result = await productService.upload(formData)
    if (result.status === HttpStatusCode.OK) {
      this.dataRequest.imageUrl = result.body.imgUrl
    } else {
      toastUtil.error(result.body.message ? result.body.message : 'Error.')
    }
  }

  reorder = (startIndex: number, endIndex: number) => {
    const results: any = Array.from(this.listProduct)
    const [removed] = results.splice(startIndex, 1);
    results.splice(endIndex, 0, removed);

    //resort
    results.forEach(function (lists, index) {
      lists.position = index
    });

    this.listProduct = results;
  };

  public = async () => {
    let rwPoolDetailDtos: any[] = [];
    rwPoolDetailDtos = this.listProduct.map(obj => ({
      id: obj.id,
      serviceId: obj.serviceId,
      spinAmount: obj.spinAmount,
      platform: obj.platform,
      name: obj.name,
      price: obj.price,
      type: obj.type,
      category: obj.category,
      sms: obj.sms,
      note: obj.note,
      imageUrl: obj.imageUrl,
      paymentMethod: obj.paymentMethod,
      state: obj.state,
      description: obj.description,
      loyaltyPackageCode: obj.loyaltyPackageCode,
    }))

    let res: any = []
    this.listProduct.map((item) => {
      res.push(item.id)
    })

    this.isLoadingBt = true
    const result = await productService.public(res)
    this.isLoadingBt = false
    if (result.status === HttpStatusCode.OK) {
      toastUtil.success('Published');
    } else {
      toastUtil.error(result.body.message);
    }
  }
}

export const productStore = new ProductStore();
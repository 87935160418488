import {observer} from "mobx-react-lite";
import NoCotent from './NoCotent'
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

const ChartStatic = observer((props: any) => {

  let statisticDate: any = [];
  let labelMap : any = [];
  let dataMap : any = [];
  let product: any = []

  if (props.dataStatistic) {
    for (let i = 0; i < props.dataStatistic.length; i++) {
      let item = props.dataStatistic[i];
      labelMap[i] = item.payments[0].productName
      item.payments.forEach((payments) => {
        product.push([Date.parse(payments.daily), parseInt(payments.totalPurchase)])
      })
      dataMap[i] = product
      product = []
    }
  }

  for (let i = 0; i < labelMap.length; i++) {
    statisticDate.push({
      name: labelMap[i],
      data: dataMap[i]
    })
  }

  const chartOptions = {
    title: {
      text: ''
    },
    xAxis: {
      gridLineWidth: 1,
      type: 'datetime',
      labels: {
        format: "{value:%Y-%m-%d}",
        rotation: -50,
        align: 'right'
      }
    },
    legend: {
      align: 'left',
      verticalAlign: 'top',
      borderWidth: 0
    },
    tooltip: {
      shared: true,
      crosshairs: true
    },
    series: statisticDate
  }
  return (
    <div className="">
      {props.dataStatistic && props.dataStatistic.length > 0 ?
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
        />
        :
        <NoCotent message={'No data'}/>
      }
    </div>
  );

})


export default ChartStatic;
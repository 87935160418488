import React, {useEffect} from 'react'
import {configStore} from './ConfigStore'
import {observer} from 'mobx-react-lite'
import NoContent from '../../common/components/NoCotent'
import Paginate from '../../common/components/Paginate'
import Loading from '../../common/components/Loading'
import ModalCreate from './components/ModalCreate'
import ModalEdit from './components/ModalEdit'
import ModalDelete from './components/ModalDelete'
import {Link} from "react-router-dom";
import {dateUtils} from "../../common/utils/DateUtils";

const ConfigWrapper = observer(() => {

  useEffect(() => {
    configStore.getAll().then()
  }, [])

  const handlePage = (page) => {
    configStore.page = page.selected
    configStore.getAll().then()
  }

  return (
    <>
      <div className="app-toolbar d-flex justify-content-between mb-5">
        <div className="app-container d-flex flex-stack container-xxl">
          <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
            <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
              Config
            </h1>
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
              <li className="breadcrumb-item text-muted">
                <Link to={"/"} className="text-muted text-hover-primary">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item">
                <span className="bullet bg-gray-500 w-5px h-2px"></span>
              </li>
              <li className="breadcrumb-item text-muted">
                Config
              </li>
            </ul>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-3">
            <div className="m-0">
              {/*<button className="btn btn-sm btn-flex fw-bold bg-body btn-color-gray-700 btn-active-color-primary"*/}
              {/*        data-kt-menu-trigger="click"*/}
              {/*        data-kt-menu-placement="bottom-end">*/}
              {/*  <i className="fas fa-filter">*/}
              {/*  </i>Filter*/}
              {/*</button>*/}
              <div className="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true">
                <div className="px-7 py-5">
                  <div className="fs-5 text-gray-900 fw-bolder">Filter Options</div>
                </div>
                <div className="separator border-gray-200"></div>
                <div className="px-7 py-5">
                  <div className="mb-10"><label className="form-label fw-bold">Status:</label>
                    <div><select className="form-select form-select-solid" data-kt-select2="true"
                                 data-placeholder="Select option" data-allow-clear="true">
                      <option></option>
                      <option value="1" selected={true}>Approved</option>
                      <option value="2">Pending</option>
                      <option value="3">In Process</option>
                      <option value="4">Rejected</option>
                    </select></div>
                  </div>
                  <div className="mb-10"><label className="form-label fw-bold">Member Type:</label>
                    <div className="d-flex"><label
                      className="form-check form-check-sm form-check-custom form-check-solid me-5"><input
                      className="form-check-input" type="checkbox" value="1"/><span
                      className="form-check-label">Author</span></label><label
                      className="form-check form-check-sm form-check-custom form-check-solid"><input
                      className="form-check-input" type="checkbox" value="2" checked={true}/><span
                      className="form-check-label">Customer</span></label></div>
                  </div>
                  <div className="mb-10"><label className="form-label fw-bold">Notifications:</label>
                    <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid"><input
                      className="form-check-input" type="checkbox" name="notifications" value="" checked={true}/><label
                      className="form-check-label">Enabled</label></div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <button type="reset" className="btn btn-sm btn-light btn-active-light-primary me-2"
                            data-kt-menu-dismiss="true">Reset
                    </button>
                    <button type="submit" className="btn btn-sm btn-primary" data-kt-menu-dismiss="true">Apply</button>
                  </div>
                </div>
              </div>
            </div>
            <button type="button" onClick={() => configStore.clearForm()}
                    className="btn btn-sm fw-bold btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#modal_create">
              Create
            </button>
          </div>
        </div>
      </div>

      <div className="app-content flex-column-fluid">
        <div className="app-container container-xxl">
          <div className="card card-flush">
            {/*<div className="card-header align-items-center py-5 gap-2 gap-md-5">*/}
            {/*  <div className="card-title">*/}
            {/*    <div className="d-flex align-items-center position-relative my-1">*/}
            {/*      <i className="bi bi-search fs-5 position-absolute ms-4"/>*/}
            {/*      <input type="text"*/}
            {/*             value={""}*/}
            {/*             className="form-control form-control-solid w-250px ps-12"*/}
            {/*             placeholder="Search"*/}

            {/*      />*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className="card-toolbar flex-row-fluid justify-content-end gap-5">*/}

            {/*  </div>*/}
            {/*</div>*/}
            <div className="card-body pt-0">
              {configStore.isLoading ?
                <Loading/>
                :
                <>
                  {configStore.listAll && configStore.listAll.length > 0 ?
                    <div className="table-responsive">
                      <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                        <thead>
                        <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                          <th className="">Id</th>
                          <th className="">Key</th>
                          <th className="">Value</th>
                          <th className="">Created At</th>
                          <th className="text-end min-w-100px">Action</th>
                        </tr>
                        </thead>
                        <tbody className="fw-semibold text-gray-600">
                        {configStore.listAll.map((item: any, i: number) => {
                          return (
                            <tr key={i}>
                              <td className="text-gray-800 fs-5 fw-bold">{item.id}</td>
                              <td className="text-gray-800 fs-5 fw-bold">{item.key}</td>
                              <td className="text-gray-800 fs-5 fw-bold">{item.value}</td>
                              <td className="text-gray-800 fs-5 fw-bold">{dateUtils.getDateTimeString(item.createdAt)}</td>
                              <td className="d-flex justify-content-end">
                                <button className="btn btn-sm btn-icon btn-warning me-3"
                                        onClick={() => configStore.getDetail(item.id)}
                                        data-bs-toggle="modal"
                                        data-bs-target="#modal_edit"
                                        title="Edit"
                                >
                                  <i className="fas fa-pen"></i>
                                </button>
                                <button className="btn btn-sm btn-icon btn-danger"
                                        onClick={() => configStore.id = item.id}
                                        data-bs-toggle="modal"
                                        data-bs-target="#modal_delete"
                                        title="Delete"
                                >
                                  <i className="fas fa-trash"></i>
                                </button>
                              </td>
                            </tr>
                          )
                        })}
                        </tbody>

                      </table>
                    </div>
                    :
                    <NoContent/>
                  }
                </>
              }

              {configStore.totalPages > 1 &&
                <Paginate currentPage={configStore.page}
                          totalPages={configStore.totalPages}
                          callback={handlePage}
                />
              }
            </div>
          </div>
        </div>
      </div>

      <ModalCreate/>
      <ModalEdit/>
      <ModalDelete/>

    </>
  )
})

export default ConfigWrapper
import {action, makeAutoObservable} from "mobx";
import {toastUtil} from "../../common/utils/ToastUtil";
import HttpStatusCode from "../../common/constants/HttpErrorCode";
import {bannerService} from "./BannerService";
import $ from 'jquery'

class BannerStore {
  id: any = ''
  page: any = 0
  key: any = ''
  totalPages: number = 0
  isLoading: boolean = false
  isLoadingBt: boolean = false
  isLoadingDetail: boolean = false
  isAction: boolean = false
  bannerType: string = 'HOME'
  dataRequest: any = {
    type: this.bannerType,
    imageUrl: '',
    linkTo: '',
    linkTracking: '',
    status: '',
    file: ''
  }
  listConfig: any[] = []

  constructor() {
    makeAutoObservable(this, {
      clearForm: action,
      getList: action,
      getDetail: action,
      create: action,
      update: action,
      delete: action,
    })
  }
  
  clearForm() {
    this.dataRequest = {
      type: this.bannerType,
      imageUrl: '',
      linkTo: '',
      linkTracking: '',
      status: '',
      file: ''
    }
  }
  
  getList = async() => {
    this.isLoading = true
    const result = await bannerService.getList()
    this.isLoading = false
    if (result.status === HttpStatusCode.OK) {
      this.listConfig = result.body.data;
      this.totalPages = result.body.metadata.totalPages
    }
  }
  
   getDetail = async(id: any) => {
    this.isLoadingDetail = true
    const result = await bannerService.getgetDetail(id)
    this.isLoadingDetail = false
    if (result.status === HttpStatusCode.OK) {
      this.dataRequest = result.body
    } else {
      toastUtil.error(result.body.message)
    }
  }
  
  create = async () => {
    const data = this.dataRequest;

    this.isLoadingBt = true;
    const result = await bannerService.create(data);
    this.isLoadingBt = false;
    if (result.status === HttpStatusCode.OK) {
      $('#create_close').trigger('click')
      toastUtil.success('Createconfig success')
      this.clearForm()
      await this.getList()
    } else toastUtil.error(result.body.message)
  }
  
  update = async () => {
    const data = this.dataRequest;

    this.isLoadingBt = true;
    const result = await bannerService.update(data);
    this.isLoadingBt = false;
    if (result.status === HttpStatusCode.OK) {
      $('#edit_close').trigger('click')
      toastUtil.success('Update config success');
      this.clearForm()
      await this.getList()
    } else toastUtil.error(result.body.message)
  }
  
  delete = async () =>  {
    this.isLoadingBt = true;
    const result = await bannerService.delete(this.id);
    this.isLoadingBt = false;
    if (result.status === HttpStatusCode.OK) {
      $('#delete_close').trigger('click')
      toastUtil.success('Delete config success')
      await this.getList()
    } else toastUtil.error(result.body.message)
  }

  uploadIcon = async () => {
    var formData = new FormData()
    const file = this.dataRequest.file
    formData.append('image_url', file)
    const result = await bannerService.upload(formData)
    if (result.status === HttpStatusCode.OK) {
      console.log(result.body)
      this.dataRequest.imageUrl = result.body.imgUrl
    } else {
      toastUtil.error(result.body.message ? result.body.message : 'Error.')
    }
  }
}

export const bannerStore = new BannerStore()
import {deleteRequest, getRequest, IApiResponse, postRequest, putRequest} from "../../common/helpers/RequestHelper";
import {configStore} from "./ConfigStore";

class ConfigService {
  public getAll(): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/configs?page=${configStore.page}&size=10`);
  }
  
  public getDetail(id: number): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/configs/${id}`);
  }
  
  public create(data: any): Promise<IApiResponse> {
    return postRequest(`/v1/delta/portal/configs`, data);
  }
  
  public update(id: number, data: any): Promise<IApiResponse> {
    return putRequest(`/v1/delta/portal/configs/${id}`, data);
  }
  
  public delete(id: number): Promise<IApiResponse> {
    return deleteRequest(`/v1/delta/portal/configs/${id}`, {id});
  }
  
}

export const configService = new ConfigService();

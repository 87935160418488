import {Type, userStore} from '../UserStore'
import {observer} from 'mobx-react-lite'
import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import NoContent from '../../../common/components/NoCotent'
import {dateUtils} from '../../../common/utils/DateUtils'
import {profileStore} from '../../../modules/profile/ProfileStore'
import {number_format} from '../../../common/utils/Utils'
import Loading from "../../../common/components/Loading";
import DateRangePicker from "../../../common/components/DateRangePicker";
import Badge from "../../../common/components/Badge";

const QuestTransaction = observer(() => {
  const {id} = useParams()

  useEffect(() => {
    userStore.countPage = 0
    userStore.next = ''
    userStore.pre = ''
    userStore.searchDate = ''
    userStore.getQuestTransaction(id).then()
  }, [])

  const enterSearch = async (e: any) => {
    if (e.key === 'Enter') {
      await searchUser()
    }
  }

  const searchUser = async () => {
    userStore.next = ''
    userStore.pre = ''
    userStore.searchDate = ''
    await userStore.getQuestTransaction(id)
  }

  const handlePage = async (type: any) => {
    userStore.typePagination = type
    if (type === Type.NEXT) {
      userStore.countPage++
      userStore.pre = ''
      userStore.next = userStore.metadata.next
      await userStore.getQuestTransaction(id)
    } else {
      userStore.countPage--
      userStore.pre = userStore.metadata.pre
      userStore.next = ''
      await userStore.getQuestTransaction(id)
    }
  }

  return (
    <div className="collection_user">

      <div className="d-flex justify-content-between align-items-center">
        <DateRangePicker
          default={[userStore.startDate, userStore.endDate]}
          onChange={async (start, end) => {
            if (start !== undefined && end !== undefined) {
              userStore.startDate = dateUtils.getDateString(start)
              userStore.endDate = dateUtils.getDateString(end)
              await userStore.getQuestTransaction(id)
            }
          }}
        />

        {/*{profileStore.profileDetail.role !== 'VIEWER' &&*/}
        {/*  <div className="d-flex">*/}
        {/*    <button className="btn btn-sm btn-outline btn-primary me-3"*/}
        {/*            data-bs-toggle="modal"*/}
        {/*            data-bs-target="#modal_changePoint"*/}
        {/*            onClick={() => {*/}
        {/*              userStore.clearForm()*/}
        {/*            }}*/}
        {/*    >*/}
        {/*      Change Point*/}
        {/*    </button>*/}
        {/*  </div>*/}
        {/*}*/}
      </div>

      {userStore.isLoadingGet ? <Loading/> :
        <>
          {userStore.questTransaction && userStore.questTransaction.length > 0 ?
            <div className="max-w-full overflow-x-auto">
              <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                <thead>
                <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                  <th>
                    Id
                  </th>
                  <th>
                    Quest Name
                  </th>
                  <th>
                    Quest Turn
                  </th>
                  <th>
                    Create At
                  </th>
                  <th>
                    End At
                  </th>
                  <th>
                    CompleteAt
                  </th>
                  <th>
                    State
                  </th>
                </tr>
                </thead>
                <tbody>
                {userStore.questTransaction && userStore.questTransaction.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        {item.id}
                      </td>
                      <td>
                        {item.questName}
                      </td>
                      <td>
                        {item.questTurnName}
                      </td>
                      <td>
                        {dateUtils.formatDate(item.createdAt)}
                      </td>
                      <td>
                        {dateUtils.formatDate(item.endAt)}
                      </td>
                      <td>
                        {dateUtils.formatDate(item.completedAt)}
                      </td>
                      <td>
                        {item.questState}
                      </td>
                    </tr>
                  )
                })}
                </tbody>
              </table>
            </div>
            :
            <NoContent/>
          }
        </>
      }

      {userStore.questTransaction && userStore.questTransaction.length > 0 &&
        <div className="d-flex justify-content-between align-items-center mt-3">
          <button className="btn btn-sm btn-light-primary"
                  disabled={userStore.countPage == 0 && true}
                  onClick={() => handlePage(Type.PRE)}>
            <i className="fas fa-arrow-left"></i> Pre
          </button>
          <button className="btn btn-sm btn-light-primary"
                  disabled={userStore.questTransaction && userStore.questTransaction.length < 10 && true}
                  onClick={() => handlePage(Type.NEXT)}>
            Next <i className="fas fa-arrow-right"></i>
          </button>
        </div>
      }

    </div>
  )
})

export default QuestTransaction

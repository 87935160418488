import {Type, userStore} from '../UserStore'
import {observer} from 'mobx-react-lite'
import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import NoContent from '../../../common/components/NoCotent'
import {dateUtils} from '../../../common/utils/DateUtils'
import Loading from "../../../common/components/Loading";
import {number_format} from "../../../common/utils/Utils";
import Badge from "../../../common/components/Badge";
import {profileStore} from "../../../modules/profile/ProfileStore";

const TransactionUser = observer(() => {
  const {id} = useParams()

  useEffect(() => {
    userStore.countPage = 0
    userStore.next = ''
    userStore.pre = ''
    userStore.searchTranId = ''
    userStore.searchProductId = ''
    userStore.searchProductName = ''
    userStore.searchType = 'Transaction Id'
    userStore.getUserTransaction(id).then()
  }, [])

  const search = async () => {
    userStore.countPage = 0
    userStore.next = ''
    userStore.pre = ''
    await userStore.getUserTransaction(id)
  }

  const handlePage = async (type: any) => {
    userStore.typePagination = type
    if (type === Type.NEXT) {
      userStore.countPage++
      userStore.pre = ''
      userStore.next = userStore.metadata.next
      await userStore.getUserTransaction(id)
    } else {
      userStore.countPage--
      userStore.pre = userStore.metadata.pre
      userStore.next = ''
      await userStore.getUserTransaction(id)
    }
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <div className="d-flex align-items-center">
          <select className="form-select form-select-solid"
                  style={{borderRight: 0, borderBottomRightRadius: 0, borderTopRightRadius: 0}}
                  value={userStore.searchType}
                  onChange={(e) => {
                    userStore.searchType = e.currentTarget.value
                    userStore.searchTranId = ''
                    userStore.searchProductId = ''
                    userStore.searchProductName = ''
                  }}
          >
            <option value="Transaction Id">Transaction Id</option>
            <option value="Product Id">Product Id</option>
            <option value="Product Name">Product Name</option>
          </select>
          <div className="d-flex align-items-center position-relative my-1">
            <i className="bi bi-search fs-5 position-absolute ms-4" onClick={() => search()}/>
            <input type="text"
                   style={{borderBottomLeftRadius: 0, borderTopLeftRadius: 0}}
                   className="form-control w-250px ps-12"
                   value={
                     userStore.searchType === "Transaction Id" ? userStore.searchTranId :
                       `${userStore.searchType === "Product Id" ? userStore.searchProductId : userStore.searchProductName}`
                   }
                   onChange={(e: any) => {
                     if (userStore.searchType === "Transaction Id") {
                       userStore.searchTranId = e.currentTarget.value
                     }
                     if (userStore.searchType === "Product Id") {
                       userStore.searchProductId = e.currentTarget.value
                     }
                     if (userStore.searchType === "Product Name") {
                       userStore.searchProductName = e.currentTarget.value
                     }
                   }}
                   onKeyDown={(e) => {
                     if (e.key === 'Enter') search().then()
                   }}
                   placeholder={`Enter ${userStore.searchType}`}
            />
          </div>
          <select className="form-select form-select-solid ms-3"
                  value={userStore.searchState}
                  onChange={(e) => {
                    userStore.searchState = e.currentTarget.value
                    search().then()
                  }}
          >
            <option value="">Choose Status</option>
            <option value="PAID">PAID</option>
            <option value="COMPLETE">COMPLETE</option>
          </select>
        </div>

        {profileStore.profileDetail.role !== 'VIEWER' &&
          <button className="btn btn-sm btn-outline btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#modal_changePackage"
                  onClick={() => {
                    userStore.clearForm()
                    userStore.getProduct().then()
                  }}
          >
            Add Transaction
          </button>
        }
      </div>

      {userStore.isLoadingGet ? <Loading/> :
        <>
          {userStore.userTransaction && userStore.userTransaction.length > 0 ?
            <div className="max-w-full overflow-x-auto">
              <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                <thead>
                <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                  <th>Transaction Id</th>
                  <th>Partner Transaction Id</th>
                  <th>Product Id</th>
                  <th>Product Name</th>
                  <th>
                    Price
                  </th>
                  <th>
                    Partner
                  </th>
                  <th>
                    Method
                  </th>
                  <th>
                    Charge Type
                  </th>
                  <th>
                    Charge Status
                  </th>
                  <th>Created At</th>
                  {/*<th className="text-end">Action</th>*/}
                </tr>
                </thead>
                <tbody>
                {userStore.userTransaction && userStore.userTransaction.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{item.paymentTransactionId}</td>
                      <td>{item.partnerTransactionId}</td>
                      <td className="text-gray-800 fs-5 fw-bold">
                        {item.product.id} ({item.product.serviceId})
                      </td>
                      <td className="text-gray-800 fs-5 fw-bold">
                        {item.product.name}
                      </td>
                      <td className="text-gray-800 fs-5 fw-bold">
                        {number_format(item.product.price)}
                      </td>
                      <td className="text-gray-800 fs-5 fw-bold">
                        {item.partner}
                      </td>
                      <td className="text-gray-800 fs-5 fw-bold">
                        {item.paymentMethod}
                      </td>
                      <td className="text-gray-800 fs-5 fw-bold">
                        {item.chargeType}
                      </td>
                      <td className="text-gray-800 fs-5 fw-bold">
                        <Badge status={item.state}/>
                      </td>
                      <td>{dateUtils.formatDate(item.createdAt)}</td>
                      {/*<td className="d-flex justify-content-end">*/}
                      {/*  <button className="btn btn-sm btn-danger"*/}
                      {/*          onClick={() => userStore.idCancel = item.paymentTransactionId}*/}
                      {/*          data-bs-toggle="modal"*/}
                      {/*          data-bs-target="#modal_cancelPackage">*/}
                      {/*    Cancel*/}
                      {/*  </button>*/}
                      {/*</td>*/}
                    </tr>
                  )
                })}
                </tbody>
              </table>
            </div>
            :
            <NoContent/>
          }
        </>
      }

      {userStore.userTransaction && userStore.userTransaction.length > 0 &&
        <div className="d-flex justify-content-between align-items-center mt-3">
          <button className="btn btn-sm btn-light-primary"
                  disabled={userStore.countPage == 0 && true}
                  onClick={() => handlePage(Type.PRE)}>
            <i className="fas fa-arrow-left"></i> Pre
          </button>
          <button className="btn btn-sm btn-light-primary"
                  disabled={userStore.userTransaction && userStore.userTransaction.length < 10 && true}
                  onClick={() => handlePage(Type.NEXT)}>
            Next <i className="fas fa-arrow-right"></i>
          </button>
        </div>
      }

    </>
  )
})

export default TransactionUser

import {observer} from 'mobx-react-lite'
import 'flatpickr/dist/themes/material_green.css'
import Flatpickr from 'react-flatpickr'
import {useRef} from 'react'

interface DatePickerProps {
  default?: any
  disabled?: boolean,
  onChange?: any
}

const DatePicker = observer((props: DatePickerProps) => {
  const initialRef: any = null;
  const fp = useRef(initialRef);
  return <div className="d-flex justify-content-end align-items-center">
    <Flatpickr
      ref={fp}
      value={props.default}
      onChange={([date]) => {
        props.onChange(date)
      }}
      options={{
        mode: 'single',
        dateFormat: 'd-m-Y',
      }}
      className="form-control form-control-solid"
      placeholder="Pick date"
      disabled={props.disabled || false}
    />
    {!props.disabled &&
      <span className="position-absolute me-4 cursor-pointer">
      <i className="fas fa-times" onClick={() => {
        if (!fp?.current?.flatpickr) return
        fp.current.flatpickr.clear()
      }} />
    </span>
    }
  </div>

})

export default DatePicker
import {exportData, getRequest, IApiResponse} from "../../common/helpers/RequestHelper";
import {transactionStore} from './TransactionStore'
import {dateUtils} from "../../common/utils/DateUtils";

class TransactionService {
  public getProduct(start: string, end: string): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/transactions?startDate=${dateUtils.getDateString(transactionStore.startDate)}&endDate=${dateUtils.getDateString(transactionStore.endDate)}${transactionStore.next ? `&next=${transactionStore.next}` : ''}${transactionStore.pre ? `&pre=${transactionStore.pre}` : ''}${transactionStore.searchMsisdn ? `&msisdn=${transactionStore.searchMsisdn}` : ''}${transactionStore.searchTranId ? `&transId=${transactionStore.searchTranId}` : ''}${transactionStore.searchPartnerTranId ? `&partnerTransId=${transactionStore.searchPartnerTranId}` : ''}${transactionStore.searchProduct ? `&productName=${transactionStore.searchProduct}` : ''}${transactionStore.searchState ? `&state=${transactionStore.searchState}` : ''}${transactionStore.searchChargeType ? `&chargeType=${transactionStore.searchChargeType}` : ''}`);
  }

  public export(): Promise<IApiResponse> {
    return exportData(`/v1/delta/portal/transactions/export?startDate=${dateUtils.getDateString(transactionStore.startDate)}&endDate=${dateUtils.getDateString(transactionStore.endDate)}${transactionStore.searchMsisdn ? `&msisdn=${transactionStore.searchMsisdn}` : ''}${transactionStore.searchTranId ? `&tranId=${transactionStore.searchTranId}` : ''}${transactionStore.searchPartnerTranId ? `&partnerTransId=${transactionStore.searchPartnerTranId}` : ''}${transactionStore.searchProduct ? `&productName=${transactionStore.searchProduct}` : ''}${transactionStore.searchState ? `&state=${transactionStore.searchState}` : ''}${transactionStore.searchChargeType ? `&chargeType=${transactionStore.searchChargeType}` : ''}`, `Transaction_${dateUtils.getDateString(transactionStore.startDate)}_${dateUtils.getDateString(transactionStore.endDate)}`);
  }

}

export const transactionService = new TransactionService();

import React from 'react'
import {observer} from 'mobx-react-lite'
import {productStore} from '../ProductStore'

const ChangeQuantity = observer(() => {
  return (
    <div className="modal fade" tabIndex={-1} id="modal_change" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Change</h5>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              id="change_close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fas fa-times" />
            </div>
          </div>
          <div className="modal-body py-lg-10 px-lg-10">
            <div className="mb-5">
              <label className="form-label">Type </label>
                <select
                  value={productStore.dataQuantity.type}
                  onChange={(e) => productStore.dataQuantity.type = e.currentTarget.value}
                  className="form-select">
                  <option value="ADD">ADD</option>
                  <option value="SUBTRACT">SUBTRACT</option>
                </select>
            </div>
            <div className="mb-5">
              <label className="form-label">Amount</label>
              <input
                value={productStore.dataQuantity.amount}
                onChange={(e) => productStore.dataQuantity.amount = e.currentTarget.value}
                type="number"
                placeholder="Enter amount"
                className="form-control"
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-sm btn-light"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button type="button" className="btn btn-sm btn-primary" onClick={() => {
              productStore.changeQuantity().then()
            }}>
              {productStore.isLoadingBt ?
                <span className="">
                  Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
                :
                'Confirm'
              }
            </button>
          </div>
        </div>
      </div>
    </div>
  )
})

export default ChangeQuantity
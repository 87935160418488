/* eslint-disable jsx-a11y/anchor-is-valid */
import {useCallback, useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {login} from "../core/_requests";
import {useAuth} from '../core/Auth'
import StorageService from '../../../common/service/StorageService'
import {observer} from 'mobx-react-lite'
import {profileStore} from '../../profile/ProfileStore'
import {useGoogleReCaptcha, withGoogleReCaptcha} from "react-google-recaptcha-v3";

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(24, 'Maximum 24 symbols')
    .required('Password is required'),
  code: Yup.string()
    .min(0, 'Code is required'),
})

const initialValues = {
  username: 'admin',
  password: 'admin@321',
  code: ''
}

const Login = observer(() => {
  const [loading, setLoading] = useState(false)
  const [warning, setWarning] = useState("")
  const {saveAuth, setCurrentUser} = useAuth()
  const [sign, setSign] = useState("")
  const {executeRecaptcha} = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    const token = await executeRecaptcha();
    setSign(token)
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.username, values.password, values.code, sign)
        saveAuth(auth)
        StorageService.setToken(auth.token);
        await profileStore.getProfile()
        setCurrentUser(profileStore.profileDetail)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The login detail is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Sign In</h1>
        {/*<div className='text-gray-400 fw-bold fs-4'>*/}
        {/*  New Here?{' '}*/}
        {/*  <Link to='/auth/registration' className='link-primary fw-bolder'>*/}
        {/*    Create an Account*/}
        {/*  </Link>*/}
        {/*</div>*/}
      </div>
      {/* begin::Heading */}

      {formik.status && (
        <div className='mb-lg-10 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {warning && (
        <div className='mb-lg-10 alert alert-warning'>
          <div className='alert-text font-weight-bold'>{warning}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Username</label>
        <input
          placeholder='username'
          {...formik.getFieldProps('username')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.username && formik.errors.username},
            {'is-valid': formik.touched.username && !formik.errors.username,}
          )}
          type='text'
          autoComplete='off'
        />
        {formik.touched.username && formik.errors.username && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.username}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            {/* end::Label */}
            {/* begin::Link */}
            {/*<Link*/}
            {/*  to='/auth/forgot-password'*/}
            {/*  className='link-primary fs-6 fw-bolder'*/}
            {/*  style={{marginLeft: '5px'}}*/}
            {/*>*/}
            {/*  Forgot Password ?*/}
            {/*</Link>*/}
            {/* end::Link */}
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          onKeyDown={(key) => {
            if (key.getModifierState("CapsLock")) {
              setWarning("CapsLock is on")
            } else {
              setWarning("")
            }
          }}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>OTP</label>
        <input
          placeholder='OTP'
          {...formik.getFieldProps('code')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.code && formik.errors.code},
            {'is-valid': formik.touched.code && !formik.errors.code,}
          )}
          type='text'
          autoComplete='off'
        />
        {formik.touched.code && formik.errors.code && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.code}</span>
          </div>
        )}
      </div>

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || loading}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'>
              </span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
})


export default withGoogleReCaptcha(Login)
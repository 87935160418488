import React from 'react'

const Badge = (props: any) => {
  switch (props.status) {
    case 'ACTIVE':
      return <span className="badge badge-light-success">Active</span>
      break
    case 'INACTIVE':
      return <span className="badge badge-light-warning">Inactive</span>
      break
    case 'BAN':
      return <span className="badge badge-light-warning">Inactive</span>
      break
    case 'ACTIVATE':
      return <span className="badge badge-light-success">Activate</span>
      break
    case 'INACTIVATE':
      return <span className="badge badge-light-warning">Inactive</span>
      break
    case 'IN_ACTIVATE':
      return <span className="badge badge-light-warning">Inactive</span>
      break
    case 'ACTIVATED':
      return <span className="badge badge-light-success">Activated</span>
      break
    case 'INACTIVATED':
      return <span className="badge badge-light-warning">Inactivated</span>
      break
    case 'DELETED':
      return <span className="badge badge-light-danger">Deleted</span>
      break
    case 'VERIFIED':
      return <span className="badge badge-light-info">Verified</span>
      break
    case 'USED':
      return <span className="badge badge-light-primary">Used</span>
    case 'NEW':
      return <span className="badge badge-light-success">New</span>
    case 'COMPLETED':
      return <span className="badge badge-light-info">Completed</span>
    case 'COMPLETE':
      return <span className="badge badge-light-info">Complete</span>
    case 'PAID':
      return <span className="badge badge-light-primary">Paid</span>
    case 'BANNED':
      return <span className="badge badge-light-danger">Banned</span>
    case 'SUB':
      return <span className="badge badge-light-primary">Sub</span>
    case 'CANCEL':
      return <span className="badge badge-light-warning">Cancel</span>
    case true:
      return <i className="fas fa-check text-success"></i>
    case false:
      return <i className="fas fa-times text-danger"></i>

    default:
      return <></>
  }

}

export default Badge
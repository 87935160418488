const NoContent = (props: any) => {
  return (
    <div className="d-flex justify-content-center align-items-center bg-white py-20">
      <div className="text-black text-center fs-5">
        {props.message ? props.message : "No Content"}
      </div>
    </div>
  );
};

export default NoContent;

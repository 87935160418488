export const data: any = [
  {
    id: 0,
    role: 'ADMIN',
    menu: [
      {
        label: '',
        items: [
          // {
          //   title: 'Dashboard',
          //   icon: 'bi bi-table',
          //   subMenu: [
          //     {
          //       title: 'Statistics',
          //       link: '/dashboard/statistics',
          //     },
          //     {
          //       title: 'Revenue',
          //       link: '/dashboard/revenue',
          //     },
          //     {
          //       title: 'Sub Package',
          //       link: '/dashboard/sub',
          //     },
          //   ],
          // },
          {
            title: 'Dashboard',
            icon: 'bi bi-table',
            link: '/dashboard/statistics',
          },
          {
            title: 'Account',
            icon: 'bi bi-person-fill-gear',
            link: '/account',
          },
          {
            title: 'User',
            icon: 'bi bi-people',
            link: '/user',
          },
          // {
          //   title: 'Level',
          //   icon: 'bi bi-bar-chart',
          //   link: '/level',
          // },
          // {
          //   title: 'Feature',
          //   icon: 'bi bi-bar-chart',
          //   link: '/feature',
          // },
          // {
          //   title: 'Banner',
          //   icon: 'bi bi-card-image',
          //   link: '/banner',
          // },
          // {
          //   title: 'Character Shop',
          //   icon: 'bi bi-cart',
          //   link: '/shop',
          // },
          // {
          //   title: 'Store Exchange',
          //   icon: 'bi bi-shop',
          //   link: '/storeExchange',
          // },
          {
            title: 'Product',
            icon: 'bi bi-box-seam',
            link: '/product/list',
          },
          {
            title: 'Transactions',
            icon: 'bi bi-cash',
            link: '/transaction',
          },
          // {
          //   title: 'Product',
          //   icon: 'bi bi-box-seam',
          //   subMenu: [
          //     {
          //       title: 'Product Included',
          //       link: '/product/type',
          //     },
          //     {
          //       title: 'Product List',
          //       link: '/product/list',
          //     },
          //   ],
          // },
          // {
          //   title: 'Leaderboard',
          //   icon: 'bi bi-bar-chart',
          //   link: '/leaderboard',
          // },
          // {
          //   title: 'Game',
          //   icon: 'bi bi-controller',
          //   subMenu: [
          //     {
          //       title: 'Category Game',
          //       link: '/game/category',
          //     },
          //     {
          //       title: 'Game Type',
          //       link: '/game/type',
          //     },
          //     {
          //       title: 'Game List',
          //       link: '/game/list',
          //     },
          //   ],
          // },
          // {
          //   title: 'Challenge',
          //   icon: 'las la-clone',
          //   link: '/challenge',
          // },
          // {
          //   title: 'Reward',
          //   icon: 'bi bi-gift',
          //   subMenu: [
          //     {
          //       title: 'Reward Type',
          //       link: '/reward/type',
          //     },
          //     {
          //       title: 'Reward Store',
          //       link: '/reward/store',
          //     },
          //     {
          //       title: 'Reward Item',
          //       link: '/reward/item',
          //     },
          //     {
          //       title: 'Reward Pool',
          //       link: '/reward/pool',
          //     },
          //   ],
          // },
          // {
          //   title: 'Collection',
          //   icon: 'bi bi-journal-album',
          //   link: '/collection',
          // },
          // {
          //   title: 'Achievement',
          //   icon: 'bi bi-award',
          //   link: '/achievement',
          // },
          // {
          //   title: 'Quest',
          //   icon: 'bi bi-question-circle',
          //   subMenu: [
          //     {
          //       title: 'Daily Quest',
          //       link: '/quest/quest',
          //     },
          //     {
          //       title: 'Quest Turn',
          //       link: '/quest/turn',
          //     },
          //   ],
          // },
          // {
          //   title: 'Task Daily',
          //   icon: 'bi bi-list-task',
          //   link: '/taskDaily',
          // },
          // {
          //   title: 'Tournament',
          //   icon: 'bi bi-fire',
          //   link: '/tournament',
          // },
          // {
          //   title: 'Payment',
          //   icon: 'bi bi-currency-dollar',
          //   link: '/payment',
          // },
          {
            title: 'Config',
            icon: 'bi bi-gear',
            link: '/config',
          },
        ],
      },
    ],
  },
  {},
]


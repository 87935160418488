import React, {useEffect, useState} from 'react'
import {userStore, Type} from './UserStore'
import {observer} from 'mobx-react-lite'
import NoContent from '../../common/components/NoCotent'
import {Link} from 'react-router-dom'
import Loading from '../../common/components/Loading'
import Badge from "../../common/components/Badge";

const UserWrapper = observer(() => {

  const [filter, setFilter] = useState(false)

  useEffect(() => {
    userStore.searchType = 'Username'
    userStore.getUsers().then()
  }, [])

  const handlePageClick = async (type: any) => {
    userStore.typePagination = type
    if (type === Type.NEXT) {
      userStore.countPage++
      userStore.pre = ''
      userStore.next = userStore.metadata.next
      await userStore.getUsers()
    } else {
      userStore.countPage--
      userStore.pre = userStore.metadata.pre
      userStore.next = ''
      await userStore.getUsers()
    }
  }

  const search = () => {
    userStore.next = ''
    userStore.pre = ''
    userStore.getUsers().then()
  }

  return (
    <>
      <div className="app-toolbar d-flex justify-content-between mb-5">
        <div className="app-container d-flex flex-stack container-xxl">
          <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
            <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
              Users
            </h1>
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
              <li className="breadcrumb-item text-muted">
                <Link to={"/"} className="text-muted text-hover-primary">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item">
                <span className="bullet bg-gray-500 w-5px h-2px"></span>
              </li>
              <li className="breadcrumb-item text-muted">
                Users
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="app-content flex-column-fluid">
        <div className="app-container container-xxl">
          <div className="card card-flush">
            <div className="card-header align-items-center py-5 gap-2 gap-md-5">
              <div className="card-title">
                <div className="d-flex align-items-center position-relative my-1">
                  <i className="bi bi-search fs-5 position-absolute ms-4" onClick={() => search()}/>
                  <input type="text"
                         value={userStore.searchPhone}
                         className="form-control form-control-solid w-250px ps-12"
                         placeholder="Enter Phone Number"
                         onChange={(e) => userStore.searchPhone = e.currentTarget.value}
                         onKeyDown={(e) => {
                           if (e.key === "Enter") {
                             search()
                           }
                         }}
                  />
                </div>
                {/*<select className="form-select form-select-solid"*/}
                {/*        style={{borderRight: 0, borderBottomRightRadius: 0, borderTopRightRadius: 0 }}*/}
                {/*        value={userStore.searchType}*/}
                {/*        onChange={(e) => {*/}
                {/*          userStore.searchType = e.currentTarget.value*/}
                {/*          userStore.searchName = ''*/}
                {/*          userStore.searchPhone = ''*/}
                {/*        }}*/}
                {/*>*/}
                {/*  <option value="Username">Username</option>*/}
                {/*  <option value="PhoneNumber">PhoneNumber</option>*/}
                {/*</select>*/}
                {/*<div className="d-flex align-items-center position-relative my-1">*/}
                {/*  <i className="bi bi-search fs-5 position-absolute ms-4" onClick={() => search()}/>*/}
                {/*  <input type="text"*/}
                {/*         style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }}*/}
                {/*         className="form-control w-250px ps-12"*/}
                {/*         value={*/}
                {/*           userStore.searchType === "Username" ? userStore.searchName :*/}
                {/*             `${userStore.searchType === "Phone" ? userStore.searchPhone : userStore.searchPhone}`*/}
                {/*         }*/}
                {/*         onChange={(e: any) => {*/}
                {/*           if (userStore.searchType === "Username") {*/}
                {/*             userStore.searchName = e.currentTarget.value*/}
                {/*           }*/}
                {/*           if (userStore.searchType === "PhoneNumber") {*/}
                {/*             userStore.searchPhone = e.currentTarget.value*/}
                {/*           }*/}
                {/*         }}*/}
                {/*         onKeyDown={(e) => {*/}
                {/*           if (e.key === 'Enter') search()*/}
                {/*         }}*/}
                {/*         placeholder={`Enter ${userStore.searchType}`}*/}
                {/*  />*/}
                {/*</div>*/}
              </div>
              <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                {/*<button className="btn btn-sm btn-primary" onClick={() => setFilter(!filter)}>*/}
                {/*  {filter ? "Less Filter" : "More Filter"}*/}
                {/*</button>*/}
                {/*<div className="d-flex justify-content-end">*/}
                {/*  <button type="reset"*/}
                {/*          className="btn btn-sm btn-light btn-active-light-primary me-2"*/}
                {/*          onClick={() => userStore.clearForm()}>*/}
                {/*    Reset*/}
                {/*  </button>*/}
                {/*  <button type="submit" className="btn btn-sm btn-primary"*/}
                {/*          onClick={() => search()}>*/}
                {/*    Apply*/}
                {/*  </button>*/}
                {/*</div>*/}
              </div>
            </div>

            {filter &&
              <div className="d-flex flex-column px-10 gap-2 gap-md-5">
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <div className="d-flex align-items-center position-relative my-1 me-3">
                      <i className="bi bi-search fs-5 position-absolute ms-4"/>
                      <input type="text"
                             value={userStore.displayName}
                             className="form-control form-control-solid w-250px ps-12"
                             placeholder="Search by name"
                             onChange={(e) => userStore.displayName = e.currentTarget.value}
                      />
                    </div>
                    <div className="d-flex align-items-center position-relative my-1">
                      <i className="bi bi-search fs-5 position-absolute ms-4"/>
                      <input type="text"
                             value={userStore.phoneNumber}
                             className="form-control form-control-solid w-250px ps-12"
                             placeholder="Search by phone"
                             onChange={(e) => userStore.phoneNumber = e.currentTarget.value}
                      />
                    </div>
                  </div>
                  {/*<div className="d-flex">*/}
                  {/*  <select className="form-select form-select-solid"*/}
                  {/*          value={userStore.searchState}*/}
                  {/*          onChange={(e) => userStore.searchState = e.currentTarget.value}*/}
                  {/*  >*/}
                  {/*    <option>Choose State</option>*/}
                  {/*  </select>*/}
                  {/*</div>*/}
                </div>
                <div className="d-flex justify-content-end">
                  <button type="reset"
                          className="btn btn-sm btn-light btn-active-light-primary me-2"
                          onClick={() => userStore.clearForm()}>
                    Reset
                  </button>
                  <button type="submit" className="btn btn-sm btn-primary"
                          onClick={() => search()}>
                    Apply
                  </button>
                </div>
              </div>
            }

            <div className="card-body pt-0">
              {userStore.isLoading ? <Loading/>
                :
                <>
                  {userStore.userList && userStore.userList.length > 0 ?
                    <div className="table-responsive">
                      <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                        <thead>
                        <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                          <th>
                            UserId
                          </th>
                          <th>
                            Phone Number
                          </th>
                          <th className="text-end">
                            Status
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        {userStore.userList.map((item: any, i: number) => {
                          const link = `/user/${item.id}`
                          return (
                            <tr key={i}>
                              <td>
                                <Link to={link}>
                                  {item.id}
                                </Link>
                              </td>
                              <td>
                                <Link to={link}>
                                  {item.username}
                                </Link>
                              </td>
                              <td className="d-flex justify-content-end">
                                <Link to={link}>
                                  <Badge status={item.state}/>
                                </Link>
                              </td>
                            </tr>
                          )
                        })}
                        </tbody>
                      </table>
                    </div>
                    :
                    <NoContent/>
                  }

                  {/*{userStore.userList && userStore.userList.length > 0 &&*/}
                  {/*  <div className="d-flex justify-content-between align-items-center mt-3">*/}
                  {/*    <button className="btn btn-sm btn-light-primary"*/}
                  {/*            disabled={userStore.countPage === 0 && true}*/}
                  {/*            onClick={() => handlePageClick(Type.PRE)}>*/}
                  {/*      <i className="fas fa-arrow-left"></i> Pre*/}
                  {/*    </button>*/}
                  {/*    <button className="btn btn-sm btn-light-primary"*/}
                  {/*            disabled={userStore.userList && userStore.userList.length < 10 && true}*/}
                  {/*            onClick={() => handlePageClick(Type.NEXT)}>*/}
                  {/*      Next <i className="fas fa-arrow-right"></i>*/}
                  {/*    </button>*/}
                  {/*  </div>*/}
                  {/*}*/}

                </>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
})

export default UserWrapper
import axios, {Method} from "axios";
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import StorageService from "../service/StorageService";
import HttpStatusCode from "../constants/HttpErrorCode";
import Constants from "../constants/Constants";
import {toastUtil} from '../utils/ToastUtil'
import {redirect} from "react-router-dom";
import humps from 'humps';

export interface IApiResponse {
  readonly status: number;
  readonly body: any;
}

export interface IBodyError {
  readonly errorCode: number;
  readonly message: string
}

// @ts-ignore
const refreshAuthLogic = (failedRequest: { response: { config: { headers: { [x: string]: any; }; }; }; }) => axios.post(Constants.API_URL + '/v1/delta/portal/auth/refreshToken', {'refreshToken': StorageService.getRefreshToken()}).then(tokenRefreshResponse => {
  StorageService.setToken(tokenRefreshResponse.data.refreshToken);
  failedRequest.response.config.headers[Constants.TOKEN_NAME] = tokenRefreshResponse.data.refreshToken;
}).catch(function () {
  StorageService.removeToken();
  StorageService.removeRefreshToken()
});

export async function getRequest(path: string): Promise<IApiResponse> {
  let newHeaders: any;
  
  if (StorageService.isTokenExits()) {
    newHeaders = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + StorageService.getToken()
    }
    createAuthRefreshInterceptor(axios, refreshAuthLogic, {
      pauseInstanceWhileRefreshing: true
    });
  } else {
    newHeaders = {'Content-Type': 'application/json'}
  }
  
  return await axios.get(Constants.API_URL + path, {headers: newHeaders})
    .then(
      (response) => {
        const apiResponse: IApiResponse = {
          status: response.status,
          body: humps.camelizeKeys(response.data),
        };
        return apiResponse;
      },
      (error) => {
        if (error.response && error.response.status === HttpStatusCode.UNAUTHORIZED || error.response.data.message == "Invalid authentication 4") {
          StorageService.removeToken();
          redirect("/")
        }
        
        let bodyError: IBodyError;
        try {
          bodyError = {
            errorCode: error.response.data.errorCode,
            message: error.response.data.message
          }
        } catch (e) {
          bodyError = {
            errorCode: HttpStatusCode.UNKNOW_ERROR,
            message: "Unknown error, please try again later"
          }
        }
        
        const apiResponse: IApiResponse = {
          status: error.response.status,
          body: bodyError
        };
        return apiResponse;
      }
    );
}

export function apiCall(path: string, _method: Method, _params: object): Promise<IApiResponse> {
  let newHeaders: any;
  
  if (StorageService.isTokenExits()) {
    newHeaders = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + StorageService.getToken()
    }
    createAuthRefreshInterceptor(axios, refreshAuthLogic, {
      pauseInstanceWhileRefreshing: true
    });
  } else {
    newHeaders = {'Content-Type': 'application/json'}
  }
  
  return new Promise<IApiResponse>((resolve) => {
    axios({
      data: JSON.stringify(_params),
      headers: newHeaders,
      method: _method,
      url: Constants.API_URL + path
    })
      .then(function (response) {
        resolve({
          status: response.status,
          body: humps.camelizeKeys(response.data),
        });
      })
      .catch(function (error) {
        if (error.response && error.response.status === HttpStatusCode.UNAUTHORIZED || error.response.data.message == "Invalid authentication 4") {
          StorageService.removeToken();
          redirect("/")
        }
        
        let bodyError: IBodyError;
        try {
          if (error.response && error.response.status === HttpStatusCode.INTERNAL_SERVER_ERROR) {
            bodyError = {
              errorCode: HttpStatusCode.INTERNAL_SERVER_ERROR,
              message: "Internal server error, please try again later"
            }
          } else {
            bodyError = {
              errorCode: error.response.data.errorCode,
              message: error.response.data.message
            }
          }
          
        } catch (e) {
          bodyError = {
            errorCode: HttpStatusCode.UNKNOW_ERROR,
            message: "Unknown error, please try again later"
          }
        }
        
        const apiResponse: IApiResponse = {
          status: error.response.status,
          body: bodyError
        };
        
        resolve(apiResponse);
      });
  });
}


export async function postRequest(path: string, params: object): Promise<IApiResponse> {
  return apiCall(path, "POST", params);
}

export async function putRequest(path: string, params: object): Promise<IApiResponse> {
  return apiCall(path, "PUT", params);
}

export async function deleteRequest(path: string, params: object): Promise<IApiResponse> {
  return apiCall(path, "DELETE", params);
}

export async function exportData(path: string, name: string): Promise<any> {
  let newHeaders: any
  if (StorageService.isTokenExits()) {
    newHeaders = {
      'Content-Type': 'text/html',
      Authorization: 'Bearer ' + StorageService.getToken(),
      Accept: 'text/html'
    }
    createAuthRefreshInterceptor(axios, refreshAuthLogic, {
      pauseInstanceWhileRefreshing: true
    });
  }
  
  return await axios.get(Constants.API_URL + path, {headers: newHeaders, responseType: "blob"})
    .then(
      (response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${name}.xlsx`)
        document.body.appendChild(link)
        link.click();
        toastUtil.success('Downloaded')
      },

      (error) => {
        toastUtil.error(error.toJSON().message)
      }
    );
}
import React from 'react'
import {observer} from 'mobx-react-lite'
import {productStore} from '../ProductStore'

const ModalCreate = observer(() => {

  const onUploadImg = async (type: string,e: any) => {
    e.preventDefault()
    let reader = new FileReader()
    let file = e.target.files[0]
    productStore.dataRequest.file = file
    await productStore.uploadIcon(type)
    reader.readAsDataURL(file)
  }

  return (
    <div className="modal fade" tabIndex={-1} id="modal_create" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-lg modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Create</h5>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              id="create_close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fas fa-times"/>
            </div>
          </div>
          <div className="modal-body py-lg-10 px-lg-10">
            <div className="row">
              <div className="col-6">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter name"
                  value={productStore.dataRequest.name}
                  onChange={(e) => productStore.dataRequest.name = e.currentTarget.value}
                />
              </div>
              <div className="col-6 mb-5">
                <label className="form-label">Category</label>
                <select className={'form-select'}
                        value={productStore.dataRequest.category}
                        onChange={(e) => productStore.dataRequest.category = e.currentTarget.value}
                >
                  <option value="">Choose category</option>
                  <option value="OTT">OTT</option>
                  <option value="GAME">GAME</option>
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col-6 mb-5">
                <label className="form-label">Description</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter description"
                  value={productStore.dataRequest.description}
                  onChange={(e) => productStore.dataRequest.description = e.currentTarget.value}
                />
              </div>
              <div className="col-6 mb-5">
                <label className="form-label">Platform</label>
                <select className={'form-select'}
                        value={productStore.dataRequest.platform}
                        onChange={(e) => productStore.dataRequest.platform = e.currentTarget.value}
                >
                  <option value="">Choose Platform</option>
                  <option value="IOS">IOS</option>
                  <option value="ANDROID">ANDROID</option>
                  <option value="BOTH">BOTH</option>
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col-6 mb-5">
                <label className="form-label">PackageId</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter id"
                  value={productStore.dataRequest.serviceId}
                  onChange={(e) => productStore.dataRequest.serviceId = e.currentTarget.value}
                />
              </div>
              <div className="col-6 mb-5">
                <label className="form-label">Charge Type</label>
                <select className={'form-select'}
                        value={productStore.dataRequest.type}
                        onChange={(e) => productStore.dataRequest.type = e.currentTarget.value}
                >
                  <option value="">Choose type</option>
                  <option value="SUB">SUB</option>
                  <option value="CHARGE">CHARGE</option>
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col-6 mb-5">
                <label className="form-label">SMS Message</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter point"
                  value={productStore.dataRequest.smsBody}
                  onChange={(e) => productStore.dataRequest.smsBody = e.currentTarget.value}
                />
              </div>
              <div className="col-6 mb-5">
                <label className="form-label">Payment Method</label>
                <select className={'form-select'}
                        value={productStore.dataRequest.paymentMethod}
                        onChange={(e) => productStore.dataRequest.paymentMethod = e.currentTarget.value}
                >
                  <option value="">Choose method</option>
                  <option value="SMS">SMS</option>
                  <option value="MPS">MPS</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-6 mb-5">
                <label className="form-label">SMS PhoneNumber</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter phonenumber"
                  value={productStore.dataRequest.smsPhone}
                  onChange={(e) => productStore.dataRequest.smsPhone = e.currentTarget.value}
                />
              </div>
              <div className="col-6 mb-5">
                <label className="form-label">Partner</label>
                <select className={'form-select'}
                        value={productStore.dataRequest.partner}
                        onChange={(e) => productStore.dataRequest.partner = e.currentTarget.value}
                >
                  <option value="">Choose partner</option>
                  <option value="VIETTEL">VIETTEL</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-6 mb-5">
                <label className="form-label">Price</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter amount"
                  value={productStore.dataRequest.price}
                  onChange={(e) => productStore.dataRequest.price = e.currentTarget.value}
                />
              </div>
              <div className="col-6 mb-5">
                <label className="form-label">Status</label>
                <select className={'form-select'}
                        value={productStore.dataRequest.state}
                        onChange={(e) => productStore.dataRequest.state = e.currentTarget.value}
                >
                  <option value="">Choose state</option>
                  <option value="ACTIVATE">ACTIVATE</option>
                  <option value="IN_ACTIVATE">IN_ACTIVATE</option>
                  <option value="COMPLETED">COMPLETED</option>
                </select>
              </div>
            </div>
            <div className="col-6 mb-5">
              <div className="d-flex flex-column">
                <label className="form-label">Icon</label>
                <div className="image-input image-input-outline"
                     style={{backgroundImage: 'url(assets/media/>users/blank.png)'}}>
                  <div className="image-input-wrapper position-relative"
                       style={{
                         backgroundImage: `url(${productStore.dataRequest.imageUrl})`,
                         backgroundSize: 'contain',
                         backgroundPosition: 'center',
                       }}>
                    <label
                      className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow position-absolute"
                      style={{top: -15, right: -20}}>
                      <i className="fas fa-pen icon-sm text-muted"></i>
                      <input className="d-none" type="file" name="profile_avatar" accept=".png, .jpg, .jpeg"
                             onChange={(e) => onUploadImg("imageUrl", e)}/>
                    </label>

                    {productStore.dataRequest.imageUrl &&
                      <span
                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow position-absolute"
                        style={{bottom: -15, right: -20}}>
		                <i className="fas fa-times icon-sm text-muted"
                       onClick={() => productStore.dataRequest.imageUrl = ''}></i>
	                </span>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-sm btn-light"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button type="button" className="btn btn-sm btn-primary" onClick={() => {
              productStore.create().then()
            }}>
              {productStore.isLoadingBt ?
                <span className="">
                  Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
                :
                'Confirm'
              }
            </button>
          </div>
        </div>
      </div>
    </div>
  )
})

export default ModalCreate
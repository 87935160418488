import moment from "moment";

class DateUtils {
  /**
   *
   * @param timeSecond
   * @param localeFormat
   */
  public formatTSDateTimeAtTZ(timeSecond: number, localeFormat: string = "en-US") {
    return new Date(timeSecond * 1000).toLocaleString(localeFormat);
  }

  /**
   *
   * @param isoDate
   * @param localeFormat
   */
  public formatISODateTimeAtTZ(isoDate: string, localeFormat: string = "vi-VN") {
    let lastChar: string = isoDate.substr(isoDate.length - 1);
    if (lastChar !== "Z") {
      isoDate += "Z"
    }
    return new Date(isoDate).toLocaleString(localeFormat);
  }

  /**
   *
   * @param isoDate isoDate UTC
   * @param localeFormat
   */
  public formatISODateAtTZ(isoDate: string, localeFormat: string = "vi-VN") {
    let lastChar: string = isoDate.substr(isoDate.length - 1);
    if (lastChar !== "Z") {
      isoDate += "Z"
    }
    return new Date(isoDate).toLocaleDateString(localeFormat);
  }

  /**
   *
   * @param isoDate isoDate UTC
   * @param localeFormat
   */
  public formatISOTimeAtTZ(isoDate: string, localeFormat: string = "vi-VN") {
    let lastChar: string = isoDate.substr(isoDate.length - 1);
    if (lastChar !== "Z") {
      isoDate += "Z"
    }
    return new Date(isoDate).toLocaleTimeString(localeFormat);
  }

  public formatDate(timeSecond: number) {
    return timeSecond ? moment(timeSecond).format('DD/MM/YYYY HH:mm:ss') : '';
  }

  public getDateString(date: any) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  public getDateTimeString(date: any) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    let hour = '' + d.getHours()
    let minute = '' + d.getMinutes()
    let second = '' + d.getSeconds()

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    if (hour.length < 2) hour = '0' + hour;
    if (minute.length < 2) minute = '0' + minute;
    if (second.length < 2) second = '0' + second;

    return [year, month, day].join('-') + " " + [hour, minute, second].join(':');
  }
}

export const dateUtils = new DateUtils();
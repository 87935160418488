import React from 'react'
import {observer} from 'mobx-react-lite'
import {userStore} from '../UserStore'
import DatePicker from "../../../common/components/DatePicker";

const ChangeSub = observer(() => {
  return (
    <div className="modal fade" tabIndex={-1} id="modal_changeSub" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Change</h5>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              id="changeSub_close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fas fa-times" />
            </div>
          </div>
          <div className="modal-body py-lg-10 px-lg-10">
            <div className="mb-5">
              <label className="form-label">Product</label>
              <select className="form-select"
                      data-placeholder="Select role"
                      value={userStore.dataSub.packageCode}
                      onChange={(e) => userStore.dataSub.packageCode = e.currentTarget.value}>
                <option value="">Choose Product</option>
                {userStore.productList && userStore.productList.map((item, i) => {
                  return <option key={i} value={item.code}>{item.name}</option>
                })}
              </select>
            </div>
            <div className="mb-5">
              <label className="form-label">Create At</label>
              <DatePicker
                default={[userStore.dataSub.startAt]}
                onChange={async (date) => {
                  userStore.dataSub.startAt = date
                }}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-sm btn-light"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button type="button" className="btn btn-sm btn-primary" onClick={() => userStore.changeSub()}>
              {userStore.isLoadingBt ?
                <span className="">
                  Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
                :
                'Confirm'
              }
            </button>
          </div>
        </div>
      </div>
    </div>
  )
})

export default ChangeSub
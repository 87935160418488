import React from 'react'
import {observer} from 'mobx-react-lite'
import {userStore} from "../UserStore";

const CancelSub = observer(() => {
  return (
    <div className="modal fade" tabIndex={-1} id="modal_cancelSub" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Cancel</h5>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              id="cancelSub_close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fas fa-times" />
            </div>
          </div>

          <div className="modal-body py-lg-10 px-lg-10">
            <p className="text-danger text-center fs-3">Are you sure ?</p>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-sm btn-light"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button type="button" className="btn btn-sm btn-primary" onClick={() => {userStore.cancelSub().then()}}>
              {userStore.isLoadingBt ?
                <span className="">
                  Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
                :
                'Confirm'
              }
            </button>
          </div>
        </div>
      </div>
    </div>
  )
})

export default CancelSub
import {observer} from 'mobx-react-lite'
import {Link, useParams} from 'react-router-dom'
import {useEffect} from 'react'
import {TabUser, userStore} from '../UserStore'
import Breadcrumb from '../../../common/components/Breadcrumb'
import {getLocalDateTime, number_format} from '../../../common/utils/Utils'
import Loading from '../../../common/components/Loading'
import SubHistory from './SubHistory'
import ChangePoint from './ChangePoint'
import CoinTransaction from "./CoinTransaction";
import PointTransaction from "./PointTransaction";
import SpinTransaction from "./SpinTransaction";
import RewardHistory from "./RewardHistory";
import ChangeSpin from "./ChangeSpin";
import CharacterHistory from "./CharacterHistory";
import ChangeCoin from "./ChangeCoin";
import ExpTransaction from "./ExpTransaction";
import TicketTransaction from "./TicketTransaction";
import AchievementUser from "./AchievementUser";
import CollectionUser from "./CollectionUser";
import LuckyPointUser from "./LuckyPointUser";
import QuestTransaction from "./QuestTransaction";
import ChangeExp from "./ChangeExp";
import ChangeTicket from "./ChangeTicket";
import ChangeLuckyPoint from "./ChangeLuckyPoint";
import ChangeSub from "./ChangeSub";
import ChangeCollection from "./ChangeCollection";
import ChangeAchievement from "./ChangeAchievement";
import GoldPig from "./GoldPig";
import TaskDaily from "./TaskDaily";
import CharacterUser from "./CharacterUser";
import ChangePointGoldPig from "./ChangePointGoldPig";
import ChangeTask from "./ChangeTask";
import CollectionHistory from "./CollectionHistory";
import ChangeCharacter from "./ChangeCharacter";
import PackageUser from "./PackageUser";
import TransactionUser from "./TransactionUser";
import ChangePackage from "./ChangePackage";
import CancelPackage from "./CancelPackage";

const userInfo = observer(() => {

    const {id} = useParams()

    useEffect(() => {
      window.scroll(0, 0)
      userStore.next = ''
      userStore.pre = ''
      userStore.userId = id
      userStore.tabActive = TabUser.package
      userStore.getUserDetail(id).then()
    }, [])

    const filterTab = (type: string) => {
      userStore.clear()
      userStore.tabActive = type
    }

    const tabContent = () => {
      switch (userStore.tabActive) {
        case TabUser.characterHistory:
          return <CharacterHistory/>
        case TabUser.characterUser:
          return <CharacterUser/>
        case TabUser.subHistory:
          return <SubHistory/>
        case TabUser.coinTransaction:
          return <CoinTransaction/>
        case TabUser.pointTransaction:
          return <PointTransaction/>
        case TabUser.expTransaction:
          return <ExpTransaction/>
        case TabUser.spinTransaction:
          return <SpinTransaction/>
        case TabUser.ticketTransaction:
          return <TicketTransaction/>
        case TabUser.rewardHistory:
          return <RewardHistory/>
        case TabUser.achievementUser:
          return <AchievementUser/>
        case TabUser.collectionHistory:
          return <CollectionHistory/>
        case TabUser.collectionUser:
          return <CollectionUser/>
        case TabUser.luckyPointUser:
          return <LuckyPointUser/>
        case TabUser.questTransaction:
          return <QuestTransaction/>
        case TabUser.goldPig:
          return <GoldPig/>
        case TabUser.taskDaily:
          return <TaskDaily/>
        case TabUser.package:
          return <PackageUser/>
        case TabUser.transaction:
          return <TransactionUser/>
        default:
          return <></>
      }
    }

    const subTitle = (label: string, value: any) => {
      return <div className="d-flex mb-1">
        <Link to="#"
              className="text-gray-500 text-hover-primary fw-bolder me-1">
          {label}
        </Link>:
        <Link to="#"
              className="text-gray-800 text-hover-primary fs-5 fw-bold ms-2">
          {value !== null ? value : '-----'}
        </Link>
      </div>
    }

    return <>
      <Breadcrumb back={true} pageName={`User: #${userStore.userInfo.id}`}/>
      {userStore.isLoading ? <Loading/>
        :
        <>
          {userStore.userInfo ?
            <>
              <div className="app-content flex-column-fluid card mb-5 p-5 pb-0">
                <div className="mb-5">
                  <div className="border-dashed border-1 p-3">
                    <div className="row">
                      <div className="col-3 d-flex flex-column">
                        {subTitle('PhoneNumber', userStore.userInfo.username)}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex overflow-auto h-55px">
                    <ul
                      className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-7 fw-bolder flex-nowrap">
                      <li className="nav-item">
                        <button
                          className={`nav-link text-active-primary me-6 ${userStore.tabActive === TabUser.package && 'active'}`}
                          onClick={() => filterTab(TabUser.package)}>Package
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          className={`nav-link text-active-primary me-6 ${userStore.tabActive === TabUser.transaction && 'active'}`}
                          onClick={() => filterTab(TabUser.transaction)}>Transaction
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="separator border-2 mb-3"></div>
                </div>
              </div>

              <div className="app-content flex-column-fluid">
                <div className="card mb-5">
                  <div className="card-body p-5">
                    {tabContent()}
                  </div>
                </div>
              </div>

              <ChangeSpin/>
              <ChangePoint/>
              <ChangeCoin/>
              <ChangeExp/>
              <ChangeTicket/>
              <ChangeLuckyPoint/>
              <ChangeSub/>
              <ChangeCollection/>
              <ChangeAchievement/>
              <ChangePointGoldPig/>
              <ChangeTask/>
              <ChangeCharacter/>
              <ChangePackage/>
              <CancelPackage/>

            </>
            :
            <></>
          }
        </>
      }
    </>
  },
)

export default userInfo
import {deleteRequest, getRequest, IApiResponse, postRequest, putRequest} from '../../common/helpers/RequestHelper'
import {gameStore} from './GameStore'

class GameService {
  public getList(): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/game?size=20&page=${gameStore.page}${gameStore.searchId ? `&id=${gameStore.searchId}` : ``}${gameStore.searchName ? `&name=${gameStore.searchName}` : ``}${gameStore.searchCategory ? `&gameCategoryId=${gameStore.searchCategory}` : ``}${gameStore.searchState ? `&status=${gameStore.searchState}` : ``}${gameStore.searchHot ? `&isHot=${gameStore.searchHot}` : ``}${gameStore.searchNew ? `&isNew=${gameStore.searchNew}` : ``}`);
  }

  public getDetail(id: number): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/game/${id}`);
  }

  public add(data: any): Promise<IApiResponse> {
    return postRequest(`/v1/delta/portal/game`, data);
  }

  public update(id: number, data: any): Promise<IApiResponse> {
    return putRequest(`/v1/delta/portal/game/${id}`, data);
  }

  public delete(id: number): Promise<IApiResponse> {
    return deleteRequest(`/v1/delta/portal/game/${id}`, {id});
  }

  public getCategory(): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/game-category/active`);
  }

  public getType(): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/game/type/active`);
  }

  public getLevel(): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/user-segment/active`);
  }

  public getPublisher(): Promise<IApiResponse> {
    return getRequest(`/v1/delta/portal/account/publisher`);
  }
}

export const gameService = new GameService()
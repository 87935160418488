import {action, makeAutoObservable} from "mobx";
import {toastUtil} from "../../common/utils/ToastUtil";
import HttpStatusCode from "../../common/constants/HttpErrorCode";
import {configService} from "./ConfigService";
import $ from 'jquery'

class ConfigStore {
  id: any = ''
  page: any = 0
  key: any = ''
  totalPages: number = 0
  isLoading: boolean = false
  isLoadingBt: boolean = false
  isLoadingDetail: boolean = false
  dataRequest: any = {
    id: '',
    key: '',
    value: ''
  }
  listAll: any[] = []

  constructor() {
    makeAutoObservable(this, {
      clearForm: action,
      getAll: action,
      getDetail: action,
      create: action,
      update: action,
      delete: action,
    })
  }

  clearForm() {
    this.dataRequest = {
      id: '',
      key: '',
      value: ''
    }
  }

  getAll = async () => {
    this.isLoading = true
    const result = await configService.getAll()
    this.isLoading = false
    if (result.status === HttpStatusCode.OK) {
      this.listAll = result.body.data;
      this.totalPages = result.body.metadata.totalPages
    }
  }

  getDetail = async (id: any) => {
    this.isLoadingDetail = true
    const result = await configService.getDetail(id)
    this.isLoadingDetail = false
    if (result.status === HttpStatusCode.OK) {
      this.dataRequest = result.body
    } else {
      toastUtil.error(result.body.message)
    }
  }

  create = async () => {
    const data: any = {
      key: this.dataRequest.key,
      value: this.dataRequest.value
    }

    if (!data.key) {
      toastUtil.warning('Key must not be null', 1)
      return
    }

    this.isLoadingBt = true;
    const result = await configService.create(data);
    this.isLoadingBt = false;
    if (result.status === HttpStatusCode.OK) {
      toastUtil.success('Create success')
      $('#create_close').trigger('click')
      this.clearForm()
      await this.getAll()
    } else toastUtil.error(result.body.message)
  }

  update = async () => {
    const data: any = {
      id: this.dataRequest.id,
      key: this.dataRequest.key,
      value: this.dataRequest.value
    }

    if (!data.key) {
      toastUtil.warning('Key must not be null', 1)
      return
    }

    this.isLoadingBt = true;
    const result = await configService.update(data.id, data);
    this.isLoadingBt = false;
    if (result.status === HttpStatusCode.OK) {
      toastUtil.success('Update success');
      $('#edit_close').trigger('click')
      this.clearForm()
      await this.getAll()
    } else toastUtil.error(result.body.message)
  }

  delete = async () => {
    this.isLoadingBt = true;
    const result = await configService.delete(this.id);
    this.isLoadingBt = false;
    if (result.status === HttpStatusCode.OK) {
      toastUtil.success('Delete success')
      $('#delete_close').trigger('click')
      await this.getAll()
    } else toastUtil.error(result.body.message)
  }
}

export const configStore = new ConfigStore()
import axios from "axios";
import StorageService from "../service/StorageService";
import HttpStatusCode from "../constants/HttpErrorCode";
import Constants from "../constants/Constants";
import humps from "humps";

export interface IApiResponse {
  readonly status: number;
  readonly body: any;
}

export interface IBodyError {
  readonly errorCode: number;
  readonly message: string
}

export function uploadFile(path: string, _params: object): Promise<IApiResponse> {
  let newHeaders: any;
  
  if (StorageService.isTokenExits()) {
    newHeaders = {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + StorageService.getToken()
    }
  } else {
    newHeaders = {'Content-Type': 'multipart/form-data'}
  }
  return new Promise<IApiResponse>((resolve) => {
    axios({
      data: _params,
      headers: newHeaders,
      method: 'POST',
      url: Constants.API_URL + path
    })
      .then(function (response) {
        resolve({
          status: response.status,
          body: humps.camelizeKeys(response.data),
        });
      })
      .catch(function (error) {
        if (error.response && error.response.status === HttpStatusCode.UNAUTHORIZED && error.response.data.message == "Invalid authentication 4") {
          StorageService.removeToken();
          window.location.href = "/"
        }
        
        let bodyError: IBodyError;
        try {
          if (error.response && error.response.status === HttpStatusCode.INTERNAL_SERVER_ERROR) {
            bodyError = {
              errorCode: HttpStatusCode.INTERNAL_SERVER_ERROR,
              message: "Internal server error, please try again later"
            }
          } else {
            bodyError = {
              errorCode: error.response.data.errorCode,
              message: error.response.data.message
            }
          }
          
        } catch (e) {
          bodyError = {
            errorCode: HttpStatusCode.UNKNOW_ERROR,
            message: "Unknow error, please try again later"
          }
        }
        
        const apiResponse: IApiResponse = {
          status: error.response.status,
          body: bodyError
        };
        
        resolve(apiResponse);
      });
    
  });
}
